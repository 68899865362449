/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container } from "@components/crud/Container";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  styled,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "@utils/formatCurrency";
import PrintIcon from "@assets/icons/printIcon.svg";
import DownloadIcon from "@assets/icons/downloadIcon.svg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SGLogo from "@assets/images/sg-logo.png";
import PaymentConfirmation, {
  PaymentConfirmationDialogProps
} from "./PaymentConfirmation";
import { PaymentDetailsForm } from "../../components/PaymentDetailsForm";
import { InvoicePDF, InvoicePDFDetailsProps } from "@components/InvoicePDF";
import {
  AdvertiserInvoicePaymentInputPayment,
  AdvertiserInvoiceSchema,
  AdvertiserInvoiceSchemaPaymentType,
  AdvertiserInvoiceSchemaStatus,
  MerchantGetResponse,
  useAdminAdvertiserInvoiceInvoiceIdGet,
  useAdminAdvertiserInvoiceInvoiceIdPaymentPost,
  useAdminMerchantGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import SGLogoLoader from "@assets/images/SGLogoLoader.gif";
import { usePDF } from "@react-pdf/renderer";
import { downloadPDFFromLink } from "@utils/downloadPdfFromLink";
import { enqueueSnackbar } from "notistack";
import { CRM_ORDER_PAYMENT_TYPES } from "@utils/constants";

const Header = styled("div")`
  background-color: #f5f5f5;
  padding: 8px 32px;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: 0px 1px 3px 0px #00000026;
  .MuiAvatar-root {
    height: 48px;
    width: 48px;
  }
  @media (max-width: 576px) {
    padding: 8px 15px;
    .MuiAvatar-root {
      height: 32px;
      width: 32px;
    }
  }
`;

const Body = styled("div")`
  padding: 32px;
  margin-top: 3px;
  width: 100%;
  background-color: #f3f4f7;
  min-height: calc(100vh - 64px);
  .payment-details {
    flex-direction: row;
  }
  .invoice-details {
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
  }
  @media (max-width: 576px) {
    padding: 24px;
  }
  @media (max-width: 1000px) {
    .payment-details {
      flex-direction: column;
      align-items: center;
    }
    .invoice-details {
      flex-direction: row;
      max-width: 650px;
    }
  }
  @media (max-width: 767px) {
    .invoice-details {
      flex-direction: column;
      width: 100%;
      div {
        width: 100%;
      }
    }
  }
`;

const FlexBox = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  line-height: 20px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;
const StyledDiv = styled("div")`
  box-shadow: 0px 0px 12px 0px #0000001f;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  width: 305px;
  padding: 12px 16px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
`;

const TitleText = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  color: #000;
  line-height: 22px;
`;

const DescriptionText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 20px;
`;

const StyledDivider = styled(Divider)`
  height: 4px;
  border-bottom-width: medium;
  border-color: #f3f4f7;
  margin-left: -16px;
  width: calc(100% + 32px);
`;

const StyledSGLogoLoader = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  img {
    width: 150px;
  }
  @media (max-width: 465px) {
    img {
      width: 100px;
    }
  }
`;

const formatDate = (isoDate: string | Date | undefined): string => {
  if (!isoDate) return "";
  const date = new Date(isoDate);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const convertDateToExpiry = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(2);

  const formattedDate = month + year;
  return formattedDate;
};

const InvoicePayment = () => {
  const navigate = useNavigate();
  const [merchantDetailsExpanded, setMerchantDetailsExpanded] = useState(false);
  const [invoiceInstance, setInvoiceInstance] = usePDF();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [invoiceInstanceProps, setInvoiceInstanceProps] = useState<
    InvoicePDFDetailsProps | undefined
  >(undefined);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<
    PaymentConfirmationDialogProps | undefined
  >(undefined);
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<AdvertiserInvoiceSchemaPaymentType>("DEBIT_CARD");
  const [invoiceDetails, setInvoiceDetails] =
    useState<AdvertiserInvoiceSchema | null>(null);
  const [merchantData, setMerchantData] = useState<MerchantGetResponse | null>(
    null
  );
  const { invoiceId } = useParams();

  const {
    data: advertiserInvoice,
    isLoading: isLoading,
    error: invoiceError
  } = useAdminAdvertiserInvoiceInvoiceIdGet(invoiceId!);

  const { mutate: save, isLoading: isSaving } =
    useAdminAdvertiserInvoiceInvoiceIdPaymentPost();

  useEffect(() => {
    if (invoiceError) navigate("/not-found");
  }, [invoiceError]);

  const { data: merchant, isLoading: isMerchantLoading } = useAdminMerchantGet(
    advertiserInvoice?.data.advertiser?.organization?.merchantId as string,
    {
      query: {
        enabled: !!advertiserInvoice?.data.advertiser?.organization?.merchantId
      }
    }
  );

  useEffect(() => {
    if (advertiserInvoice?.data) {
      setInvoiceDetails(advertiserInvoice?.data);
      const advertiserInvoiceData = advertiserInvoice?.data;
      const props: InvoicePDFDetailsProps = {
        from: {
          name: advertiserInvoiceData.advertiser?.organization?.name as string,
          address:
            advertiserInvoiceData.advertiser?.organization?.lines?.[0] +
            ", " +
            advertiserInvoiceData.advertiser?.organization?.locality +
            ", " +
            advertiserInvoiceData.advertiser?.organization?.province +
            ", " +
            advertiserInvoiceData.advertiser?.organization?.country
        },
        invoiceNumber: advertiserInvoiceData.invoiceId as string,
        orderDate: advertiserInvoiceData.createdAt as Date,
        paymentDate: advertiserInvoiceData?.paidAt,
        paymentMode: advertiserInvoiceData.paymentType
          ? `${CRM_ORDER_PAYMENT_TYPES.find(
              (c) => c.value === advertiserInvoiceData.paymentType
            )?.label} - ${advertiserInvoiceData.payment?.number}`
          : "",
        terms: "Due Upon Receipt",
        paymentStatus:
          advertiserInvoiceData.status as AdvertiserInvoiceSchemaStatus,
        billTo: {
          to: advertiserInvoiceData.advertiser?.businessName as string,
          name:
            advertiserInvoiceData.advertiser?.primaryContact?.firstName +
            " " +
            advertiserInvoiceData.advertiser?.primaryContact?.lastName,
          email: advertiserInvoiceData.advertiser?.primaryContact
            ?.workEmail as string,
          phone: advertiserInvoiceData.advertiser?.primaryContact
            ?.workPhone as string
        },
        items: [
          {
            name: `${advertiserInvoiceData.advertiser?.sponsorship?.name} Sponsorship`,
            amount: advertiserInvoiceData.amountPreTax as number,
            quantity: 1,
            desciption: [
              {
                name: "Ad Placements",
                subDescription: [
                  advertiserInvoiceData.advertiser?.sponsorship
                    ?.sponsoredByEnabled && {
                    name: "Sponsored By Ad - Live Stream Event Feed Posts",
                    isBold: true
                  },
                  advertiserInvoiceData.advertiser?.sponsorship
                    ?.outStreamEnabled && {
                    name: "Out-Stream Ad - View Live Stream Screen",
                    isBold: true
                  },
                  advertiserInvoiceData.advertiser?.sponsorship
                    ?.inStreamEnabled && {
                    name: "In-Stream Ad - Live Streaming Intermissions",
                    isBold: true
                  }
                ].filter(Boolean)
              },
              {
                name: `Ads will run on ${advertiserInvoiceData.advertiser
                  ?.organization?.name} from ${formatDate(
                  new Date(advertiserInvoiceData.dueAt as Date)
                )} to ${formatDate(
                  new Date(
                    new Date(advertiserInvoiceData.dueAt as Date).setFullYear(
                      new Date(
                        advertiserInvoiceData.dueAt as Date
                      ).getFullYear() + 1
                    )
                  )
                )}`
              },
              {
                name: "Unlimited Impressions"
              }
            ]
          }
        ]
      };
      setInvoiceInstanceProps(props);
      setInvoiceInstance(
        <InvoicePDF hasFooter={true} hasHeader={false} invoiceDetails={props} />
      );
      if (advertiserInvoice.data.status === "PAID") {
        setConfirmationDialogProps({
          organization: {
            name: advertiserInvoiceData?.advertiser?.organization
              ?.name as string,
            phone: advertiserInvoiceData?.advertiser?.organization
              ?.phone as string,
            address:
              advertiserInvoiceData?.advertiser?.organization?.lines?.[0] +
              ", " +
              advertiserInvoiceData?.advertiser?.organization?.locality +
              ", " +
              advertiserInvoiceData?.advertiser?.organization?.province +
              ", " +
              advertiserInvoiceData?.advertiser?.organization?.country,
            email: advertiserInvoiceData?.advertiser?.organization
              ?.email as string
          },
          paymentDetails: {
            type: advertiserInvoiceData.paymentType as AdvertiserInvoiceSchemaPaymentType,
            number: advertiserInvoiceData.payment?.number as string,
            transactionId: advertiserInvoiceData.transactionId as string
          },
          dueDate: advertiserInvoiceData?.dueAt,
          amount:
            (advertiserInvoiceData?.amountPreTax as number) +
            (advertiserInvoiceData?.taxAmount as number),
          invoiceId: advertiserInvoiceData.invoiceId as string,
          to: advertiserInvoiceData.advertiser?.businessName as string
        });
        setPaymentSuccess(true);
      }
    }
  }, [advertiserInvoice]);

  useEffect(() => {
    if (merchant?.data) setMerchantData(merchant?.data);
  }, [merchant]);

  useEffect(() => {
    if (invoiceInstanceProps)
      setInvoiceInstance(
        <InvoicePDF
          hasFooter={true}
          hasHeader={false}
          invoiceDetails={invoiceInstanceProps}
        />
      );
  }, [invoiceInstanceProps]);

  if (isLoading || isMerchantLoading) {
    return (
      <StyledSGLogoLoader>
        <img src={SGLogoLoader} />
      </StyledSGLogoLoader>
    );
  }

  const createPayment = async (paymentData) => {
    let payment: AdvertiserInvoicePaymentInputPayment;
    if (selectedPaymentType.includes("CARD")) {
      payment = {
        number: paymentData.number,
        cvv: paymentData.cvv,
        expiration: convertDateToExpiry(paymentData.expirationDate)
      };
    } else {
      payment = {
        method: paymentData.accountType,
        number: paymentData.number,
        routing: paymentData.routingNumber
      };
    }
    if (invoiceDetails) {
      let total: string | number =
        (invoiceDetails.amountPreTax! + invoiceDetails.taxAmount!) * 100;

      const adjustedTotal = selectedPaymentType.includes("CARD")
        ? total * 1.03
        : total;

      total = adjustedTotal.toFixed(0);
      save(
        {
          invoiceId: invoiceId!,
          data: {
            merchantId: advertiserInvoice?.data.advertiser?.organization
              ?.merchantId as string,
            type: selectedPaymentType,
            total: total,
            payment: payment,
            ...(!selectedPaymentType.includes("CARD") && {
              first: paymentData.accountName.split(" ")?.[0],
              last: paymentData.accountName.split(" ")?.[1]
            })
          }
        },
        {
          onSuccess: (data) => {
            const invoiceData = data.data;
            setInvoiceInstanceProps({
              ...(invoiceInstanceProps as InvoicePDFDetailsProps),
              paymentStatus: "PAID",
              paymentDate: new Date(),
              paymentMode: `${CRM_ORDER_PAYMENT_TYPES.find(
                (c) => c.value === selectedPaymentType
              )?.label} - ${invoiceData?.payment?.number}`
            });
            setConfirmationDialogProps({
              organization: {
                name: invoiceData?.advertiser?.organization?.name as string,
                phone: invoiceData?.advertiser?.organization?.phone as string,
                address:
                  invoiceData?.advertiser?.organization?.lines?.[0] +
                  ", " +
                  invoiceData?.advertiser?.organization?.locality +
                  ", " +
                  invoiceData?.advertiser?.organization?.province +
                  ", " +
                  invoiceData?.advertiser?.organization?.country,
                email: invoiceData?.advertiser?.organization?.email as string
              },
              to: invoiceData.advertiser?.businessName as string,
              paymentDetails: {
                type: selectedPaymentType,
                number: paymentData.number.slice(-4),
                transactionId: invoiceData.transactionId as string
              },
              dueDate: invoiceDetails?.dueAt,
              amount:
                (invoiceDetails?.amountPreTax as number) +
                (invoiceDetails?.taxAmount as number),
              invoiceId: invoiceDetails?.invoiceId as string
            });
            setPaymentSuccess(true);
          },
          onError: () => {
            enqueueSnackbar("Payment failed", { variant: "error" });
          }
        }
      );
    }
  };

  return (
    <Container>
      <Header>
        <Avatar
          variant="square"
          src={
            invoiceDetails?.advertiser?.organization?.avatar?.baseUrl &&
            invoiceDetails?.advertiser?.organization?.avatar?.path
              ? invoiceDetails?.advertiser?.organization?.avatar?.baseUrl +
                invoiceDetails?.advertiser?.organization?.avatar?.path
              : ""
          }
        />
        <HeaderText>
          {invoiceDetails?.advertiser?.organization?.name}
        </HeaderText>
      </Header>

      <Body>
        {!paymentSuccess ? (
          <FlexBox
            style={{ justifyContent: "center", gap: "24px" }}
            className="payment-details"
          >
            <PaymentDetailsForm
              onSaveClick={(data) => createPayment(data)}
              details={{
                amount:
                  (invoiceDetails?.amountPreTax as number) +
                  (invoiceDetails?.taxAmount as number)
              }}
              isSaving={isSaving}
              setPaymentType={setSelectedPaymentType}
            />
            <FlexBox className="invoice-details">
              <StyledDiv>
                <FlexBox>
                  <DescriptionText>Invoice</DescriptionText>
                  <TitleText>{invoiceDetails?.invoiceId}</TitleText>
                </FlexBox>
                <FlexBox>
                  <DescriptionText>Due Date</DescriptionText>
                  <TitleText>
                    {" "}
                    {invoiceDetails?.sentAt
                      ? formatDate(
                          new Date(
                            new Date(invoiceDetails.sentAt).setDate(
                              new Date(invoiceDetails.sentAt).getDate() + 7
                            )
                          )
                        )
                      : ""}
                  </TitleText>
                </FlexBox>
                <FlexBox>
                  <DescriptionText>Invoice Amount</DescriptionText>
                  <TitleText>
                    {formatCurrency(
                      (invoiceDetails?.amountPreTax as number) +
                        (invoiceDetails?.taxAmount as number)
                    )}
                  </TitleText>
                </FlexBox>
                <FlexBox>
                  <DescriptionText>Status</DescriptionText>
                  <TitleText
                    style={{
                      borderRadius: "20px",
                      padding: "4px 8px",
                      backgroundColor: !invoiceDetails?.paidAt
                        ? "#FFEEEE"
                        : "#BBF7D0",
                      color: !invoiceDetails?.paidAt ? "#A11212" : "#1ABC9C",
                      fontSize: "12px",
                      lineHeight: "15px"
                    }}
                  >
                    {invoiceDetails?.paidAt ? "Paid" : "Unpaid"}
                  </TitleText>
                </FlexBox>
                <StyledDivider />
                <FlexBox>
                  <TitleText>Total</TitleText>
                  <TitleText>
                    {formatCurrency(
                      (invoiceDetails?.amountPreTax as number) +
                        (invoiceDetails?.taxAmount as number) +
                        (selectedPaymentType.includes("CARD")
                          ? ((invoiceDetails?.amountPreTax as number) +
                              (invoiceDetails?.taxAmount as number)) *
                            0.03
                          : 0)
                    )}
                  </TitleText>
                </FlexBox>
                <StyledDivider />

                <FlexBox style={{ alignItems: "center" }}>
                  <DescriptionText
                    style={{
                      color: "#007AFF",
                      fontWeight: 500,
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      if (invoiceInstance?.url) {
                        window.open(invoiceInstance.url, "_blank");
                      }
                    }}
                  >
                    View Invoice
                  </DescriptionText>
                  <TitleText>
                    <FlexBox>
                      <IconButton
                        onClick={() =>
                          downloadPDFFromLink(
                            invoiceInstance.url,
                            `${invoiceDetails?.invoiceId}.pdf`
                          )
                        }
                      >
                        <img src={DownloadIcon} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          if (invoiceInstance?.url) {
                            const newWindow = window.open(
                              invoiceInstance.url,
                              "_blank"
                            );
                            if (newWindow) {
                              newWindow.onload = () => {
                                setTimeout(() => {
                                  newWindow.print();
                                }, 50);
                              };
                            }
                          }
                        }}
                      >
                        <img src={PrintIcon} />
                      </IconButton>
                    </FlexBox>
                  </TitleText>
                </FlexBox>
              </StyledDiv>
              <StyledDiv style={{ gap: "2px" }}>
                <FlexBox>
                  <TitleText>Merchant Details</TitleText>
                </FlexBox>
                <FlexBox>
                  <DescriptionText style={{ fontWeight: 500 }}>
                    email: &nbsp;
                    <span
                      style={{
                        fontWeight: 500,
                        color: "#007AFF",
                        cursor: "pointer"
                      }}
                    >
                      {merchantData?.merchant?.entity?.email}
                    </span>
                  </DescriptionText>
                </FlexBox>
                <Divider style={{ marginTop: "12px" }} />
                <FlexBox style={{ justifyContent: "center" }}>
                  <IconButton
                    onClick={() =>
                      setMerchantDetailsExpanded(!merchantDetailsExpanded)
                    }
                  >
                    {merchantDetailsExpanded ? <ExpandMore /> : <ExpandLess />}
                  </IconButton>
                </FlexBox>
                {!merchantDetailsExpanded && (
                  <>
                    <FlexBox
                      style={{
                        justifyContent: "flex-start",
                        gap: "8px",
                        alignItems: "center"
                      }}
                    >
                      <PermContactCalendarOutlinedIcon
                        style={{ color: "#666666" }}
                      />
                      <DescriptionText
                        style={{
                          color: "#007AFF",
                          fontWeight: 500,
                          cursor: "pointer"
                        }}
                      >
                        {merchantData?.merchant?.entity?.phone}
                      </DescriptionText>
                    </FlexBox>
                    <FlexBox
                      style={{
                        justifyContent: "flex-start",
                        gap: "8px",
                        alignItems: "center"
                      }}
                    >
                      <RoomOutlinedIcon style={{ color: "#666666" }} />
                      <DescriptionText
                        style={{
                          color: "#007AFF",
                          fontWeight: 500,
                          cursor: "pointer"
                        }}
                      >
                        {merchantData?.merchant?.entity?.address1},{" "}
                        {merchantData?.merchant?.entity?.city},{" "}
                        {merchantData?.merchant?.entity?.state},{" "}
                        {merchantData?.merchant?.entity?.country}
                      </DescriptionText>
                    </FlexBox>
                  </>
                )}
              </StyledDiv>
            </FlexBox>
          </FlexBox>
        ) : (
          <FlexBox
            style={{ justifyContent: "center", gap: "24px" }}
            className="payment-details"
          >
            <PaymentConfirmation
              confirmationDetails={
                confirmationDialogProps as PaymentConfirmationDialogProps
              }
              invoiceInstance={invoiceInstance}
            />
          </FlexBox>
        )}
        <FlexBox style={{ justifyContent: "center", marginTop: "32px" }}>
          <FlexBox style={{ alignItems: "center", gap: "10px" }}>
            <span>Powered by</span>
            <img src={SGLogo} style={{ width: "130px", height: "32px" }} />
          </FlexBox>
        </FlexBox>
      </Body>
    </Container>
  );
};

export default InvoicePayment;
