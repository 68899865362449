import { IconButton, Modal, styled, Typography } from "@mui/material";
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import MinimizeRoundedIcon from "@mui/icons-material/MinimizeRounded";
import MaximizeRoundedIcon from "@mui/icons-material/MaximizeRounded";
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";
import {
  participant,
  SearchPartipants
} from "../components/SearchParticipants";
import { useForm } from "react-hook-form";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { FormInput } from "@components/FormInput";
import { EmailEditor } from "./EmailEditor";
import formatFullName from "@utils/formatFullName";
import { QuickAddContactModal } from "../contacts/components/QuickAddContactForm";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import {
  ModelActivityRelatesTo,
  ModelEmailTemplate,
  ModelMedia,
  useAdminEmailTemplateGet,
  usePostAdminCrmMail
} from "@sportsgravyengineering/sg-api-react-sdk";
import { uploadAdminMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { enqueueSnackbar } from "notistack";
import CustomEmailDropdown from "./EmailTemplateDropdown";
const StyledBox = styled("div")`
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 16px 0px #00000040;
  width: 650px;
  background-color: #fff;
`;

const StyledHeader = styled("div")`
  border-radius: 10px 10px 0 0;
  padding: 6px 24px;
  background-color: #e8eeff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledBody = styled("div")`
  .MuiFormLabel-root {
    display: none;
  }
  .MuiBox-root .MuiGrid-root {
    padding-bottom: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiInputBase-root {
    padding: 0;
  }
  .participant-search input {
    padding-top: 5px;
  }
  .subject input {
    padding-left: 0;
  }
  .MuiNativeSelect-select,
  .participant-textfield input {
    padding-left: 0;
  }
  .MuiNativeSelect-select option:first-of-type {
    display: none;
  }
`;

const StyledDiv = styled("div")`
  border-bottom: 1px solid #e5e5e5;
  padding: 0 24px;
`;

const IconStyle = {
  height: "20px",
  width: "20px",
  color: "#000"
};

export const SendEmail = ({
  sendTo
}: {
  sendTo: {
    to: string | undefined;
    name: string | undefined;
    accountId: string | undefined;
    relatesTo: ModelActivityRelatesTo;
    contactId?: string;
    leadId?: string;
    opportunityId?: string;
  };
}) => {
  const { setEmailTo } = useContext(CallEmailTemplateContext);
  dayjs.extend(timezone);
  const [isExpaned, setIsExpaned] = useState(true);
  const { data: emailTemplates, isLoading: isLoadingEmailTemplates } =
    useAdminEmailTemplateGet({ pageSize: 100 });
  const [emailTemplateOptions, setEmailTemplateOptions] = useState<
    { departmentId: string; values: ModelEmailTemplate[] }[]
  >([]);
  useEffect(() => {
    if (emailTemplates?.data) {
      const tpls = emailTemplates.data.templates;
      const groupedByDepartment = tpls.reduce((acc, curr) => {
        if (!acc[curr.departmentId]) {
          acc[curr.departmentId] = {
            departmentId: curr.departmentId,
            values: []
          };
        }
        acc[curr.departmentId].values.push(curr);

        return acc;
      }, {});
      const result = Object.values(groupedByDepartment);
      setEmailTemplateOptions(result);
    }
  }, [emailTemplates]);
  console.log("IMAZ___________", emailTemplateOptions, isLoadingEmailTemplates);
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showParticipantsFields, setShowParticipantsFields] = useState(true);
  const [showTextField, setShowTextField] = useState(false);

  const [descValid, setDescValid] = useState(true);
  const [to, setTo] = useState<participant[]>([
    {
      label: sendTo.name || "",
      value: sendTo.to || "",
      text: sendTo.to || ""
    }
  ]);
  const [cc, setCC] = useState<participant[]>([]);
  const [bcc, setBCC] = useState<participant[]>([]);
  const [files, setFiles] = useState<{ id: string; file: File | ModelMedia }[]>(
    []
  );
  const [addContact, setAddContact] = useState<boolean>(false);
  const [addContactTo, setAddContactTo] = useState<string>();
  console.log("HELLO___", descValid, to, cc, bcc, files);
  const [uploadingFile, setUploadingFiles] = useState(false);
  const { control, getValues, setValue } = useForm({
    mode: "onBlur",
    defaultValues: {
      to: [
        {
          label: sendTo.name || "",
          text: sendTo.to || "",
          value: sendTo.to || ""
        }
      ],
      cc: [],
      bcc: [],
      template: "",
      subject: "",
      participantTextfield: "",
      hasMedia: false,
      description: ""
    }
  });

  const toggleModal = () => {
    if (!isExpaned) setIsExpaned(!isExpaned);
    setIsModalOpen(!isModalOpen);
  };

  const handleFieldFocus = () => {
    setShowParticipantsFields(true);
    setShowTextField(false);
  };

  const handleFieldBlur = () => {
    setShowParticipantsFields(false);
    setShowTextField(true);
  };

  const handleTextFieldClick = () => {
    setShowParticipantsFields(true);
    setShowTextField(false);
  };

  const EmailRecipients = useMemo(() => {
    const toValues: { value: string; label: string }[] = getValues("to");
    const ccValues: { value: string; label: string }[] = getValues("cc");
    const bccValues: { value: string; label: string }[] = getValues("bcc");

    const toText =
      toValues.length > 0
        ? `${toValues.map((item) => item.label).join(", ")}, `
        : "";

    const ccText =
      ccValues.length > 0
        ? `Cc: ${ccValues.map((item) => item.label).join(", ")}, `
        : "";

    const bccText =
      bccValues.length > 0
        ? `Bcc: ${bccValues.map((item) => item.label).join(", ")}`
        : "";
    const emptyParticipants =
      toValues.length == 0 && ccValues.length == 0 && bccValues.length == 0;
    const fullText = `${toText}${ccText}${bccText}`;
    const maxChars = isModalOpen ? 125 : 75;
    let displayedText = emptyParticipants ? "Recipients" : fullText;
    let pendingCount = 0;

    if (fullText.length > maxChars) {
      const names = fullText.split(", ");
      displayedText = "";
      for (let i = 0; i < names.length; i++) {
        if (
          (displayedText + names[i]).length +
            (pendingCount > 0 || i > 0 ? 2 : 0) <=
          maxChars
        ) {
          displayedText += (i === 0 ? "" : ", ") + names[i];
        } else {
          pendingCount = names.length - i;
          break;
        }
      }
    }
    return (
      <span
        style={{
          fontSize: "14px",
          cursor: "pointer",
          color: emptyParticipants ? "#a2a2a2" : "#000"
        }}
      >
        {displayedText}
        {pendingCount > 0 && (
          <span
            style={{
              backgroundColor: "#F3F4F7",
              border: "1px solid #E5E5E5",
              padding: "4px",
              borderRadius: "4px",
              marginLeft: "8px",
              color: "#0F0F0F",
              fontSize: "14px",
              fontWeight: 600
            }}
          >
            {`${pendingCount} more`}
          </span>
        )}
      </span>
    );
  }, [getValues, isModalOpen, showParticipantsFields, showTextField]);

  const closeEmail = () => {
    setEmailTo(undefined);
  };

  const validateDescriptionArray = (value) => {
    if (value && value.length >= 2) {
      setDescValid(true);
    } else if (value && value.length == 1 && value[0].insert !== "\n") {
      setDescValid(true);
    } else {
      setDescValid(false);
    }
  };

  const { mutate: save, isLoading: isSaving } = usePostAdminCrmMail();
  const [selectedTemplate, setSelectedTemplate] = useState("");
  console.log("IMAZ___", selectedTemplate);
  const EmailForm = ({ style }: { style?: CSSProperties }) => {
    return (
      <StyledBox style={style}>
        <StyledHeader>
          <Typography style={{ fontWeight: 500, fontSize: "16px" }}>
            New Message
          </Typography>
          <div>
            {!isExpaned ? (
              <IconButton onClick={() => setIsExpaned(!isExpaned)}>
                <MaximizeRoundedIcon style={IconStyle} />
              </IconButton>
            ) : (
              <IconButton onClick={() => setIsExpaned(!isExpaned)}>
                <MinimizeRoundedIcon style={IconStyle} />
              </IconButton>
            )}
            {!isModalOpen ? (
              <IconButton onClick={() => toggleModal()}>
                <OpenInFullRoundedIcon style={IconStyle} />
              </IconButton>
            ) : (
              <IconButton onClick={() => toggleModal()}>
                <CloseFullscreenRoundedIcon style={IconStyle} />
              </IconButton>
            )}
            <IconButton onClick={closeEmail}>
              <CloseRoundedIcon style={IconStyle} />
            </IconButton>
          </div>
        </StyledHeader>
        {isExpaned && (
          <StyledBody>
            {showParticipantsFields && (
              <StyledDiv onFocus={handleFieldFocus}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span style={{ color: "#666666" }}>To</span>
                  <SearchPartipants
                    label=""
                    control={control}
                    name="to"
                    required={false}
                    showAddOption={!!sendTo.accountId}
                    labelField="email"
                    tagField="name"
                    type="EMAIL"
                    onChange={(option) => {
                      setTo(option);
                    }}
                    addNewlick={
                      sendTo.accountId
                        ? () => {
                            setAddContact(true);
                            setAddContactTo("to");
                          }
                        : undefined
                    }
                  />
                </div>
                {showCc && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px"
                    }}
                  >
                    <span style={{ color: "#666666" }}>Cc</span>
                    <SearchPartipants
                      label=""
                      control={control}
                      name="cc"
                      required={false}
                      showAddOption={!!sendTo.accountId}
                      labelField="email"
                      tagField="name"
                      type="EMAIL"
                      onChange={(option) => {
                        setCC(option);
                      }}
                      addNewlick={
                        sendTo.accountId
                          ? () => {
                              setAddContact(true);
                              setAddContactTo("cc");
                            }
                          : undefined
                      }
                    />
                  </div>
                )}
                {showBcc && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px"
                    }}
                  >
                    <span style={{ color: "#666666" }}>Bcc</span>
                    <SearchPartipants
                      label=""
                      control={control}
                      name="bcc"
                      required={false}
                      showAddOption={!!sendTo.accountId}
                      labelField="email"
                      tagField="name"
                      type="EMAIL"
                      onChange={(option) => {
                        setBCC(option);
                      }}
                      addNewlick={
                        sendTo.accountId
                          ? () => {
                              setAddContact(true);
                              setAddContactTo("bcc");
                            }
                          : undefined
                      }
                    />
                  </div>
                )}
                {(!showCc || !showBcc) && (
                  <div
                    style={{
                      display: "flex",
                      gap: "16px",
                      marginBottom: "14px"
                    }}
                  >
                    {!showCc && (
                      <Typography
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                          color: "#666666"
                        }}
                        onClick={() => setShowCc(true)}
                      >
                        Cc
                      </Typography>
                    )}
                    {!showBcc && (
                      <Typography
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                          color: "#666666"
                        }}
                        onClick={() => setShowBcc(true)}
                      >
                        Bcc
                      </Typography>
                    )}
                  </div>
                )}
              </StyledDiv>
            )}
            {showTextField && (
              <StyledDiv
                onClick={handleTextFieldClick}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  height: "48px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {EmailRecipients}
              </StyledDiv>
            )}
            <StyledDiv
              onClick={handleFieldBlur}
              style={{
                paddingLeft: "10px"
              }}
            >
              <CustomEmailDropdown
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate}
                templateOptions={emailTemplateOptions}
                isLoadingOptions={isLoadingEmailTemplates}
                setSelectedTemplateId={(templateId) => {
                  const templateSelected =
                    emailTemplates?.data?.templates?.find(
                      (t) => t.templateId === templateId
                    );
                  if (templateSelected) {
                    setValue("description", templateSelected.body, {
                      shouldDirty: true,
                      shouldValidate: true
                    });
                    setValue("subject", templateSelected.subject);
                    setFiles(
                      templateSelected.attachments
                        ? [
                            ...templateSelected.attachments.map((a) => ({
                              id: a.mediaId!,
                              file: a
                            }))
                          ]
                        : []
                    );
                  }
                }}
              />
            </StyledDiv>
            <StyledDiv onClick={handleFieldBlur}>
              <FormInput
                type="text"
                control={control}
                name="subject"
                label=""
                TextProps={{ placeholder: "Subject", className: "subject" }}
              />
            </StyledDiv>
            <div onClick={handleFieldBlur}>
              <EmailEditor
                onClose={closeEmail}
                html={
                  getValues().description ? getValues().description : undefined
                }
                onBlur={() => {
                  validateDescriptionArray(getValues().description);
                }}
                onValueChange={(deltaOperations, hasMedia) => {
                  setValue("description", deltaOperations, {
                    shouldValidate: true,
                    shouldDirty: true
                  });
                  setValue("hasMedia", hasMedia);

                  validateDescriptionArray(deltaOperations);
                }}
                style={{
                  height: "350px"
                }}
                files={files}
                setFiles={setFiles}
                onSubmit={() => {
                  onSendEmail();
                }}
                isSending={uploadingFile || isSaving}
              />
            </div>
          </StyledBody>
        )}
      </StyledBox>
    );
  };

  const onSendEmail = async () => {
    const data = {
      to: to.map((t) => ({
        email: t.text,
        name: t.label
      })),
      cc: cc.map((t) => ({
        email: t.text,
        name: t.label
      })),
      bcc: bcc.map((t) => ({
        email: t.text,
        name: t.label
      })),
      body: getValues().description,
      subject: getValues().subject,
      relatesTo: sendTo.relatesTo,
      ...(sendTo.accountId && {
        accountId: sendTo.accountId
      }),
      ...(sendTo.contactId && {
        contactId: sendTo.contactId
      }),
      ...(sendTo.leadId && {
        leadId: sendTo.leadId
      }),
      ...(sendTo.opportunityId && {
        opportunityId: sendTo.opportunityId
      }),
      timezone: dayjs.tz.guess(),
      attachments: [] as string[]
    };
    if (files.length > 0) {
      setUploadingFiles(true);
      const filesPromises = await Promise.all(
        files.map((file) => {
          if (file.file instanceof File) {
            const promise = uploadAdminMediaUsingPresignedUrl(file.file);
            return promise;
          } else return file.id;
        })
      );
      setUploadingFiles(false);
      data.attachments = filesPromises as string[];
    }
    save(
      {
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Email Sent!", {
            variant: "success"
          });
          closeEmail();
        },
        onError: () => {
          enqueueSnackbar("Failed to Send Email!", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <>
      {sendTo.accountId && addContactTo && (
        <QuickAddContactModal
          showAddContactModal={addContact}
          setShowAddContactModal={setAddContact}
          selectedAccount={sendTo.accountId}
          onSave={(resp) => {
            if (resp.contactId && resp.email) {
              const values = getValues()[addContactTo!];
              const newValues = [...values];
              newValues.push({
                label: formatFullName(resp),
                value: resp.contactId,
                text: resp.email
              });
              setValue(addContactTo, newValues, {
                shouldDirty: true,
                shouldValidate: true
              });
              switch (addContactTo) {
                case "cc":
                  setCC(newValues);
                  break;
                case "bcc":
                  setBCC(newValues);
                  break;
                case "to":
                  setTo(newValues);
                  break;
              }
            }
          }}
        />
      )}
      {isModalOpen && isExpaned ? (
        <Modal open={true}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh"
            }}
          >
            <EmailForm
              style={{
                margin: "auto",
                display: "block",
                borderRadius: "10px",
                width: "70vw"
              }}
            />
          </div>
        </Modal>
      ) : (
        !addContact && (
          <div>
            <EmailForm style={{ width: isExpaned ? "650px" : "400px" }} />
          </div>
        )
      )}
    </>
  );
};
