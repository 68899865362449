import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography, styled } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { AddIcon } from "@components/Icons";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { AccountDetails } from "./components/AccountDetails";
import { ContactDetails } from "./components/ContactDetails";
import { OpportunityDetails } from "./components/OpportunityDetails";
import { ActivityDetails } from "./components/ActivityDetails";
import { useNavigate } from "react-router-dom";
import { CRM_OPPORTUNITY_TYPES } from "@utils/constants";
import { useEffect, useState } from "react";

const StyledHeader = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

const StyledLink = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
`;

const TabHeader = ({
  header,
  buttonText,
  onClick
}: {
  header: string;
  buttonText?: string;
  onClick?: () => void;
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <StyledHeader>{header}</StyledHeader>
      {buttonText && onClick && (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {buttonText.includes("Edit") ? (
            <Edit style={{ height: "16px", width: "16px", color: "#007aff" }} />
          ) : (
            <AddIcon
              style={{ height: "16px", width: "16px", color: "#007aff" }}
            />
          )}
          <StyledLink onClick={onClick}>{buttonText}</StyledLink>
        </div>
      )}
    </div>
  );
};

export const AccountDashboard = ({
  accountId,
  details
}: {
  accountId: string;
  details;
}) => {
  const navigate = useNavigate();
  const [selectedContact, setSelectedContact] = useState<string | null>(null);

  useEffect(() => {
    if (details && details.contacts.length > 0) {
      setSelectedContact(details.contacts[0].contact.contactId);
    }
  }, [details]);

  return (
    <Container>
      <Form>
        <Grid container spacing={3}>
          <Grid md={6} xs={12} container direction="column">
            <Grid xs={12}>
              <TabHeader
                header="Account Details"
                buttonText="Edit Account"
                onClick={() => navigate(`/crm/accounts/${accountId}/edit`)}
              />
              <HeaderUnderLine width="100%" />
              <AccountDetails
                details={{
                  accountId: accountId,
                  ...details
                }}
                hideParent={!details.parent.name}
                onParentClick={() =>
                  navigate(`/crm/accounts/${details.parent.accountId}`)
                }
              />
            </Grid>
            {details.activities.length > 0 && (
              <Grid xs={12}>
                <TabHeader
                  header="Activity Details"
                  buttonText="Add Activity"
                  onClick={() =>
                    navigate(`/crm/activities/create?accountId=${accountId}`)
                  }
                />
                <HeaderUnderLine width="100%" />
                <ActivityDetails details={details.activities} />
              </Grid>
            )}
          </Grid>
          <Grid md={6} xs={12} container direction="column">
            {details?.contacts?.length > 0 && (
              <Grid xs={12}>
                <TabHeader
                  header="Contact Details"
                  buttonText="Edit Contact"
                  onClick={() => {
                    navigate(`/crm/contacts/${selectedContact}/edit`);
                  }}
                />
                <HeaderUnderLine width="100%" />
                <ContactDetails
                  account={details}
                  contactSelected={setSelectedContact}
                  contactDetails={details.contacts.map((contact) => ({
                    contact: contact.contact,
                    isPrimary: contact.isPrimary
                  }))}
                />
              </Grid>
            )}
            {details.opportunities.length > 0 && (
              <Grid xs={12}>
                <TabHeader header="Opportunity Details" />
                <HeaderUnderLine width="100%" />
                <div style={{ marginTop: "24px" }}>
                  {details.opportunities.map((opportunity) => (
                    <OpportunityDetails
                      details={{
                        ...opportunity,
                        type: CRM_OPPORTUNITY_TYPES.find(
                          (t) => t.value === opportunity.type
                        )?.label,
                        noOfAthletes: opportunity.numberOfAthletes,
                        costPerAthlete: 20
                      }}
                      key={opportunity.opportunityId}
                    />
                  ))}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};
