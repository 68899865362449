import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Add } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Tab,
  TabProps,
  Tabs,
  Typography,
  styled
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import colors from "theme/colors";
import Grid from "@mui/system/Unstable_Grid";
import { Container } from "@components/crud/Container";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormDatePicker } from "@components/FormDatePicker";
import { useLookupCountryCountryIdSubdivisionGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { OwnerAddress } from "./OwnerAddress";
import StarIcon from "@mui/icons-material/Star";
import { DeleteIcon } from "@components/Icons";
import { EMAIL_REGEX } from "@utils/validation";
import { capitalizeFirstCharacter } from "@utils/capitalize";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    style={{
      minHeight: "36px",
      maxHeight: "36px"
    }}
  />
))({});

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  () => ({
    textTransform: "none",
    color: "#0F0F0F",
    backgroundColor: "#F3F4F7",
    fontWeight: "lighter",
    maxHeight: "36px",
    minHeight: "36px",
    "&.Mui-selected": {
      color: "#0F0F0F",
      backgroundColor: "#E8EEFF"
    }
  })
);

const genId = () => {
  const array = new Uint32Array(2);
  window.crypto.getRandomValues(array);
  return array[0] * array[1];
};

export const AboutOwner = ({ disabled }: { disabled: boolean }) => {
  const { control, watch, getValues, reset, setValue, trigger } =
    useFormContext();
  const businessType = watch("type");
  const [ownerDetails, setOwnerDetails] = useState([
    {
      id: genId(),
      firstName: "Owner",
      lastName: "Detail",
      isPrimary: false,
      hasSameAddress: false
    }
  ]);
  const [selectedOwner, setSelectedOwner] = useState<
    | {
        id: number;
        firstName: string;
        lastName: string;
        isPrimary: boolean;
        hasSameAddress: boolean;
      }
    | undefined
  >(ownerDetails[0]);
  const country = watch("country");
  const { data: provinces, isFetching: provinceLoading } =
    useLookupCountryCountryIdSubdivisionGet(country as string, {
      query: {
        enabled: !!country
      }
    });

  const provincesOptions = useMemo(() => {
    if (provinces) {
      return provinces.data.map((province) => ({
        value: province.subdivisionId as string,
        label: province.name as string
      }));
    }
    return [];
  }, [provinces]);

  useEffect(() => {
    if (ownerDetails.length > 0) {
      setSelectedOwner(ownerDetails[0]);
    }
    if (getValues("ownerDetails").length > 0) {
      reset({
        ...getValues(),
        ownerDetails: getValues("ownerDetails").map((od) => ({
          id: genId(),
          ...od
        }))
      });
      const ownerDetails = getValues("ownerDetails").map((od) => ({
        id: genId(),
        firstName: od.firstName || "Owner",
        lastName: od.lastName || "Detail",
        isPrimary: od.isPrimary,
        hasSameAddress: od.hasSameAddress
      }));
      setOwnerDetails(ownerDetails);
      setSelectedOwner({
        id: ownerDetails[0].id,
        firstName: ownerDetails[0].firstName,
        lastName: ownerDetails[0].lastName,
        isPrimary: ownerDetails[0].isPrimary,
        hasSameAddress: ownerDetails[0].hasSameAddress
      });
    }
    if (getValues("ownerDetails").length == 0 || !getValues("ownerDetails")) {
      const details = {
        id: genId(),
        firstName: "Owner",
        lastName: "Detail",
        isPrimary: false,
        hasSameAddress: false
      };
      setOwnerDetails([details]);
      setSelectedOwner(details);
    }
  }, []);

  const deleteOwner = (id: number) => {
    const newOwners = ownerDetails.filter((own) => own.id !== id);
    const deleteOwner = ownerDetails.findIndex((own) => own.id === id);
    const updatedOwner = getValues();
    setSelectedOwner(newOwners[0]);
    setOwnerDetails(newOwners);
    reset({
      ...updatedOwner,
      ownerDetails: updatedOwner.ownerDetails.filter(
        (_, index) => index !== deleteOwner
      )
    });
    trigger();
  };

  return (
    <Container>
      <Grid container direction="column" spacing={3}>
        {businessType !== "0" && (
          <div>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid>
                <Typography
                  variant="permissionNames"
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    letterSpacing: "10%",
                    lineHeight: "14.52px",
                    opacity: "50%"
                  }}
                >
                  OWNER DETAILS
                </Typography>
              </Grid>
              {!disabled && (
                <Grid>
                  <Button
                    startIcon={<Add />}
                    disabled={disabled}
                    style={{
                      textTransform: "capitalize",
                      color: colors.primary.main
                    }}
                    onClick={() => {
                      const newOwner = {
                        id: genId(),
                        firstName: "Owner",
                        lastName: "Detail",
                        isPrimary: false,
                        hasSameAddress: false
                      };
                      setOwnerDetails([...ownerDetails, newOwner]);
                      setSelectedOwner(newOwner);
                    }}
                  >
                    Add Owner
                  </Button>
                </Grid>
              )}
            </Grid>
            <HeaderUnderLine width="100%" />
          </div>
        )}
        {businessType !== "0" && (
          <StyledTabs
            value={selectedOwner?.id as number}
            onChange={(event, newValue) => {
              setSelectedOwner(ownerDetails.find((o) => o.id === newValue));
            }}
          >
            {ownerDetails.map((owner, idx) => {
              return (
                <StyledTab
                  label={`${owner.firstName} ${owner.lastName}`}
                  value={owner.id}
                  key={idx}
                  icon={
                    <div>
                      {owner.isPrimary && (
                        <StarIcon
                          htmlColor={colors.error.main}
                          style={{ height: "12px" }}
                        />
                      )}
                      {ownerDetails.length > 1 && !disabled ? (
                        <IconButton
                          disabled={disabled}
                          onClick={() => deleteOwner(owner.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : undefined}
                    </div>
                  }
                  iconPosition="end"
                  onClick={() => {
                    setSelectedOwner(owner);
                  }}
                />
              );
            })}
          </StyledTabs>
        )}
        <Grid container spacing={3} key={selectedOwner?.id}>
          {(() => {
            const owner = selectedOwner;
            const index = ownerDetails.findIndex((o) => o.id === owner?.id);
            const hasSameAddress = watch(
              `ownerDetails[${index}].hasSameAddress`
            );
            if (index === -1) {
              setSelectedOwner(ownerDetails[0]);
              return null;
            }
            return (
              <>
                {(owner?.isPrimary ||
                  !ownerDetails.find((o) => o.isPrimary) ||
                  ownerDetails.length === 1) && (
                  <Grid xs={12}>
                    <FormCheckbox
                      label="Primary Owner"
                      name={`ownerDetails[${index}].primary`}
                      control={control}
                      onChange={(e) => {
                        const newOwnerDetails = [...ownerDetails];
                        newOwnerDetails[index].isPrimary = e.target.checked;
                        setOwnerDetails(newOwnerDetails);
                      }}
                      disabled={disabled}
                    />
                  </Grid>
                )}
                <Grid xs={4} key={index}>
                  <FormInput
                    name={`ownerDetails[${index}].firstName`}
                    control={control}
                    rules={{ required: "First Name is required" }}
                    label="First Name"
                    type="text"
                    required={true}
                    disabled={disabled}
                    onChange={(e) => {
                      const newOwnerDetails = [...ownerDetails];
                      newOwnerDetails[index].firstName = (
                        e as ChangeEvent<HTMLInputElement>
                      ).target.value;
                      setOwnerDetails(newOwnerDetails);
                      setValue(
                        `ownerDetails[${index}].firstName`,
                        capitalizeFirstCharacter(
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        )
                      );
                    }}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormInput
                    name={`ownerDetails[${index}].middleName`}
                    control={control}
                    label="Middle Name"
                    type="text"
                    disabled={disabled}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormInput
                    name={`ownerDetails[${index}].lastName`}
                    control={control}
                    rules={{ required: "Last Name is required" }}
                    label="Last Name"
                    type="text"
                    required={true}
                    disabled={disabled}
                    onChange={(e) => {
                      const newOwnerDetails = [...ownerDetails];
                      newOwnerDetails[index].lastName = (
                        e as ChangeEvent<HTMLInputElement>
                      ).target.value;
                      setOwnerDetails(newOwnerDetails);
                      setValue(
                        `ownerDetails[${index}].lastName`,
                        capitalizeFirstCharacter(
                          (e as ChangeEvent<HTMLInputElement>).target.value
                        )
                      );
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  {!disabled ? (
                    <FormDatePicker
                      name={`ownerDetails[${index}].dob`}
                      control={control}
                      label="DOB"
                      disabled={disabled}
                      required
                      rules={{ required: "DOB is required" }}
                      trigger={trigger}
                    />
                  ) : (
                    <FormInput
                      control={control}
                      name={`ownerDetails[${index}].dob`}
                      type="text"
                      disabled={disabled}
                      required
                      label="DOB"
                      rules={{ required: "DOB is required" }}
                    />
                  )}
                </Grid>
                <Grid xs={6}>
                  <FormInput
                    type="text"
                    name={`ownerDetails[${index}].ssn`}
                    control={control}
                    label="SSN"
                    disabled={disabled}
                    TextProps={{
                      inputProps: { maxLength: 9 }
                    }}
                    required
                    rules={{
                      required: "SSN is required",
                      validate: (value) => value.length === 9 || "Invalid SSN"
                    }}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormInput
                    type="text"
                    name={`ownerDetails[${index}].title`}
                    control={control}
                    label="Business Title"
                    disabled={disabled}
                    required
                    rules={{
                      required: "Business Title is required"
                    }}
                  />
                </Grid>
                {businessType !== "0" && (
                  <Grid xs={6}>
                    <FormInput
                      type="text"
                      name={`ownerDetails[${index}].ownership`}
                      control={control}
                      label="Ownership %"
                      disabled={disabled}
                      required
                      rules={{
                        required: "Ownership Percentage is required",
                        validate: (value) =>
                          value <= 100 ||
                          "Ownership Percentage cannot be greater than 100"
                      }}
                    />
                  </Grid>
                )}
                <Grid xs={6}>
                  <FormInput
                    type="text"
                    name={`ownerDetails[${index}].dl`}
                    control={control}
                    label="Driving License"
                    disabled={disabled}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormSelect
                    name={`ownerDetails[${index}].dlState`}
                    control={control}
                    label="DL State"
                    disabled={disabled}
                    options={provincesOptions}
                    isLoading={provinceLoading}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormCheckbox
                    label="Significant Responsibilities"
                    name={`ownerDetails[${index}].significantResponsibility`}
                    control={control}
                    disabled={disabled}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormCheckbox
                    label="Politically Exposed Person"
                    name={`ownerDetails[${index}].politicallyExposed`}
                    control={control}
                    disabled={disabled}
                  />
                </Grid>
                {!disabled && (
                  <Grid
                    container
                    direction="row"
                    spacing={3}
                    sx={{ marginLeft: "0px", marginTop: "1px" }}
                    xs={12}
                  >
                    <Grid>
                      <FormCheckbox
                        control={control}
                        name={`ownerDetails[${index}].hasSameAddress`}
                        label="Home Address is the same as"
                        disabled={disabled}
                        onChange={(e) => {
                          const newOwnerDetails = [...ownerDetails];
                          newOwnerDetails[index].hasSameAddress =
                            e.target.checked;
                          setOwnerDetails(newOwnerDetails);
                        }}
                      />
                    </Grid>
                    <Grid marginTop="10px">
                      <FormSelect
                        sx={{
                          height: "20px"
                        }}
                        disabled={disabled}
                        name=""
                        defaultValue={"OFFICE"}
                        options={[
                          {
                            label: "Office Address",
                            value: "OFFICE"
                          }
                        ]}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid xs={6}>
                  <FormInput
                    type="tel"
                    control={control}
                    label="Mobile Phone"
                    name={`ownerDetails[${index}].phone`}
                    required
                    rules={{
                      required: "Mobile Phone is required"
                    }}
                    disabled={disabled}
                  />
                </Grid>
                <Grid xs={6}>
                  <FormInput
                    type="text"
                    control={control}
                    label="Email"
                    name={`ownerDetails[${index}].email`}
                    required
                    rules={{
                      validate: (value) => {
                        if (!EMAIL_REGEX.test(value) && value && value != "") {
                          return "Invalid Email";
                        }
                        if (!value) return "Email is required";
                      }
                    }}
                    disabled={disabled}
                  />
                </Grid>
                {!hasSameAddress && (
                  <OwnerAddress index={index} disabled={disabled} />
                )}
              </>
            );
          })()}
        </Grid>
      </Grid>
    </Container>
  );
};
