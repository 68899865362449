import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ModelAccount,
  useAdminAccountAccountIdActivityChangeLogGet,
  useDeleteAdminCrmAccountAccountId,
  useGetAdminCrmAccountAccountId
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { Loader } from "@components/crud/Loader";
import { AccountDashboard } from "./AccountDashboard";
import { AccountView } from "./AccountView";
import { AccountContacts } from "./AccountContacts";
import { AccountActivity } from "./AccountActivity";
import { AccountLeads } from "./AccountLeads";
import { AccountOpportunity } from "./AccountOpportunity";
import {
  CRM_ACCOUNT_CATEGORIES,
  ORGANIZATION_TYPE_OPTIONS
} from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import { AccountOrders } from "./AccountOrders";

export const AccountOverview = () => {
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState({
    delete: false,
    edit: false,
    view: false
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const view = await checkPermission("crm.accounts", "VIEW");
      const edit = await checkPermission("crm.accounts", "EDIT");
      const del = await checkPermission("crm.accounts", "DELETE");
      setPermissions({ view, edit, delete: del });
      if (!view) navigate("/not-found");
    };
    fetchPermissions();
  }, []);
  const [searchParams, setSearchParams] = useSearchParams();
  const [accountToDelete, setAccountToDelete] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useParams();
  const [tab, setTab] = useState(
    searchParams.get("tab") || "Account Dashboard"
  );

  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
    setSearchParams({ tab: value as string });
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setTab(searchParams.get("tab") as string);
    }
  }, [searchParams]);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useDeleteAdminCrmAccountAccountId();

  const onConfirmDelete = async () => {
    if (!accountToDelete) return;
    try {
      await deleteAsync({ accountId: accountId! });
      enqueueSnackbar("Account deleted successfully", {
        variant: "success"
      });
      setAccountToDelete(null);
      navigate("/crm/accounts");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete account!.", {
        variant: "error"
      });
      setAccountToDelete(null);
    }
  };

  const { data: accountData, isLoading: isLoading } =
    useGetAdminCrmAccountAccountId(accountId!);
  const { data: activitiesLog, isLoading: isLoadingActivityLog } =
    useAdminAccountAccountIdActivityChangeLogGet(accountId!);
  return (
    <Loader isLoading={isLoading || isLoadingActivityLog}>
      <Container>
        <Toolbar
          title="View Account"
          secondaryTitle={accountData?.data?.name}
          backBtnClick={() => navigate("/crm/accounts")}
          {...(permissions.edit && {
            editBtnClick: () => navigate(`/crm/accounts/${accountId}/edit`)
          })}
          {...(permissions.delete && {
            deleteBtnClick: () => setAccountToDelete(accountId!)
          })}
          tabs={{
            tabs: [
              "Account Dashboard",
              "Account Details",
              "Contacts",
              "Activity",
              "Leads",
              "Opportunities",
              "Orders"
            ],
            onTabChange: onTabChange,
            activeTab: tab
          }}
        />
        {tab == "Account Dashboard" && (
          <AccountDashboard
            accountId={accountId!}
            details={{
              name: accountData?.data?.name,
              type:
                CRM_ACCOUNT_CATEGORIES.find(
                  (a) => a.value === accountData?.data?.category
                )?.label || "",
              orgType:
                ORGANIZATION_TYPE_OPTIONS.find(
                  (o) => o.value === accountData?.data?.type
                )?.label || "",
              officeAddress: accountData?.data?.officeAddress,
              billingAddress: accountData?.data?.billingAddress,
              shippingAddress: accountData?.data?.shippingAddress,
              phone: accountData?.data?.phone,
              fax: accountData?.data?.fax,
              email: accountData?.data?.email,
              website: accountData?.data?.website,
              sports: accountData?.data?.sports?.map((s) => s.name) || [],
              noOfAthletes: accountData?.data?.numberOfAthletes,
              bdrOwner: accountData?.data?.bdrOwner?.person
                ? formatFullName(accountData.data.bdrOwner.person)
                : "",
              aeOwner: accountData?.data?.aeOwner?.person
                ? formatFullName(accountData?.data?.aeOwner?.person)
                : "",
              csmOwner: accountData?.data?.csmOwner?.person
                ? formatFullName(accountData?.data?.csmOwner?.person)
                : "",
              parent: {
                name: accountData?.data?.parent?.name || "",
                accountId: accountData?.data?.parent?.accountId || ""
              },
              opportunities: accountData?.data?.opportunites,
              activities: activitiesLog?.data || [],
              contacts: accountData?.data?.contacts
            }}
          />
        )}
        {tab == "Account Details" && (
          <AccountView accountDetails={accountData?.data} />
        )}
        {tab == "Contacts" && (
          <AccountContacts
            contacts={accountData?.data?.contacts}
            account={accountData?.data as ModelAccount}
            onClickAdd={() => {
              navigate(`/crm/contacts/create?accountId=${accountId}`);
            }}
          />
        )}
        {tab == "Activity" && (
          <AccountActivity
            activities={accountData?.data?.activities}
            onClickAdd={() => {
              navigate(`/crm/activities/create?accountId=${accountId}`);
            }}
            account={accountData?.data as ModelAccount}
          />
        )}
        {tab == "Leads" && (
          <AccountLeads
            leads={accountData?.data?.leads}
            onClickAdd={() => {
              navigate(`/crm/leads/create?accountId=${accountId}`);
            }}
          />
        )}
        {tab == "Opportunities" && (
          <AccountOpportunity
            account={accountData?.data as ModelAccount}
            opportunities={accountData?.data?.opportunites}
            onClickAdd={() => {
              navigate(`/crm/opportunities/create?accountId=${accountId}`);
            }}
          />
        )}
        {tab == "Orders" && (
          <AccountOrders
            orders={accountData?.data.orders}
            onClickAdd={() =>
              navigate(`/crm/orders/create?accountId=${accountId}`)
            }
          />
        )}
      </Container>
      <ConfirmationDialog
        open={!!accountToDelete}
        title="Delete Account?"
        //@ts-ignore
        body={
          <>
            Are you sure you want to delete the account{" "}
            <b>“{accountData?.data?.name}”</b> from your system?
          </>
        }
        close={() => setAccountToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setAccountToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Loader>
  );
};
