import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import { useAdminPresetTagPost } from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PresetTagForm } from "./PresetTagForm";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";

export const PresetTagCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const organizationId = useRecoilValue(organizationAtom);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      isActive: "true"
    }
  });
  const {
    handleSubmit,
    formState: { isValid },
    reset
  } = form;

  const { mutate: save, isLoading: isSaving } = useAdminPresetTagPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      values.organizationId = organizationId;
      values.isActive = values.isActive === "true";
      save(
        {
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Preset Tag added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate("/preset-tags");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add tag!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add Clip Pre-set Tag" />
      <FormProvider {...form}>
        <PresetTagForm />
      </FormProvider>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/preset-tags")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
