import { Container } from "@components/crud/Container";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { OrderDetailsForm } from "./OrderDetailsForm";
import { useForm } from "react-hook-form";
import {
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  useAdminCrmOrderOrderIdGet,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import formatFullName from "@utils/formatFullName";
import { Form } from "@components/crud/Form";
import { OrderAgreements } from "./OrderAgreements";
import { OrderPaymentDetails } from "./OrderPaymentDetails";
import { OrderBillingHistory } from "./OrderBillingHistory";
import { FullName } from "@utils/types";
import { hasPermission } from "@services/Casbin";
import { getCostPerAthlete } from "@utils/GetCostPerAthlete";
import { Tier } from "@pages/settings/CRMSetting";
import { OrderApproval } from "@pages/settings/CRMApprovalSettings";

export const OrderView = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });
  const { reset } = form;

  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      details: ContactResponse;
    }[]
  >([]);

  const [permissions, setPermissions] = useState({
    edit: false,
    view: false,
    delete: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("crm.orders", "DELETE");
      const edit = await checkPermission("crm.orders", "EDIT");
      const view = await checkPermission("crm.orders", "VIEW");
      if (!view) navigate("/not-found");
      const permission = {
        delete: del,
        edit,
        view
      };
      setPermissions(permission);
    };
    fetchPermissions();
  }, []);

  const { data: orderData, isLoading: isLoading } = useAdminCrmOrderOrderIdGet(
    orderId!
  );
  const { data: settings, isLoading: isLoadingConfig } = useConfigGet();
  const [pricingTier, setPricingTier] = useState<Tier[]>([]);
  const [minOrderApprovalAmount, setMinOrderApprovalAmount] =
    useState<number>();
  useEffect(() => {
    if (settings && settings.data) {
      const orderApprovalsData = (settings.data.find(
        (item) => item.key === "crm.order.approvals-required"
      )?.value || []) as OrderApproval[];

      const minApprovalAmount = orderApprovalsData.reduce((min, current) => {
        const currentAmount = parseFloat(current.amount.replace(/,/g, ""));
        return currentAmount < min ? currentAmount : min;
      }, Infinity);
      setMinOrderApprovalAmount(minApprovalAmount);
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);
    }
  }, [settings]);
  useEffect(() => {
    if (orderData?.data) {
      const order = orderData.data;
      reset({
        submittedBy: formatFullName(order.submittedBy?.person as FullName),
        orderDate: new Date(order.date),
        product: order.product,
        orderType: order.type,
        noOfAthletes: order.numberOfAthletes,
        costOfAthletesPeryear: `$ ${getCostPerAthlete(
          order.numberOfAthletes!,
          pricingTier
        )} Per Athlete, Per Registration`,
        amount: (
          order.numberOfAthletes! *
          getCostPerAthlete(order.numberOfAthletes!, pricingTier)
        ).toFixed(2),
        billingFrequency: order.billingFrequency,
        status: order.status,
        notes: order.notes,
        account: {
          name: order.account?.name || "",
          parent: order.account?.parent?.name || "",
          category: order.account?.category,
          type: order.account?.type,
          officeAddress: order.account?.officeAddress,
          email: order.account?.email,
          website: order.account?.website,
          sportsOffered: order.account?.sports?.map((s) => s.sportId) || [],
          noOfAthletes: order.account?.numberOfAthletes,
          aeOwner: order.account?.aeOwner?.person
            ? formatFullName(order.account?.aeOwner?.person)
            : ""
        },
        contact: {
          firstName: order.contact?.firstName,
          lastName: order.contact?.lastName,
          personalEmail: order.contact?.email,
          homeAddress: order.contact?.location,
          phoneType: "MOB",
          phone: order.contact?.phone,
          whatsappNumber: order.contact?.whatsappNumber
        }
      });
      setSelectedAccount(order.accountId || "");
    }
  }, [orderData]);
  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && orderData?.data) {
        try {
          const data = await getAdminCrmAccountAccountIdContact(
            selectedAccount
          );
          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c.isPrimary,
              details: c.contact as ContactResponse
            }))
          );
          if (orderData.data.accountId !== selectedAccount) {
            const primaryOrFirstContact =
              data.data.find((c) => c.isPrimary)?.contactId ||
              data.data?.[0]?.contactId;

            setSelectedContact(primaryOrFirstContact || "");
          } else {
            setSelectedContact(orderData.data.contactId!);
          }
        } catch (error) {
          setContacts([]);
        }
      } else setSelectedContact("");
    };
    fetchData();
  }, [selectedAccount, orderData]);

  const [tab, setTab] = useState(searchParams.get("tab") || "Order Details");
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
    setSearchParams({ tab: value as string });
  };
  useEffect(() => {
    if (searchParams.get("tab")) {
      setTab(searchParams.get("tab") as string);
    }
  }, [searchParams]);
  const getTabs = () => {
    return [
      "Order Details",
      "Agreements",
      "Payment Details",
      "Billing History"
    ];
  };

  return (
    <Container>
      <Toolbar
        title={`View Order: ${orderId}`}
        backBtnClick={() => {
          navigate("/crm/orders");
        }}
        {...(permissions.edit &&
          tab === "Order Details" && {
            editBtnClick: () => navigate(`/crm/orders/${orderId}/edit`)
          })}
        addBtnClick={
          tab === "Payment Details"
            ? () => {
                navigate(`/crm/orders/${orderId}/paymentDetail/create`);
              }
            : undefined
        }
        addBtnLabel="Add"
        tabs={{
          tabs: getTabs(),
          onTabChange: onTabChange,
          activeTab: tab
        }}
      />
      <Loader isLoading={isLoading || isLoadingConfig}>
        {tab === "Order Details" && (
          <Form>
            <OrderDetailsForm
              form={form}
              disabled
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              contacts={contacts}
              minApprovalAmount={minOrderApprovalAmount!}
              setContacts={setContacts}
            />
          </Form>
        )}
        {tab === "Agreements" && <OrderAgreements orderId={orderId!} />}
        {tab === "Payment Details" && (
          <OrderPaymentDetails orderId={orderId!} />
        )}
        {tab === "Billing History" && <OrderBillingHistory />}
      </Loader>
    </Container>
  );
};
