/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddressAutocomplete } from "@components/AddressAutocomplete";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import Grid from "@mui/system/Unstable_Grid";
import { getCountries } from "@services/Network";
import { useLookupCountryCountryIdSubdivisionGet } from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";

export const OwnerAddress = ({
  index,
  disabled
}: {
  index: number;
  disabled: boolean;
}) => {
  const { control, setValue, trigger, watch } = useFormContext();
  const country = watch(`ownerDetails[${index}].country`);
  const { data: countries, isFetching: isFetchingCountries } = getCountries({
    staleTime: Infinity
  });

  const countryOptions = useMemo(() => {
    if (countries) {
      return countries.map((country) => ({
        value: country.countryId!,
        label: country.name!,
        altId: country.countryAltId!
      }));
    }
    return [];
  }, [countries]);
  const { data: provinces, isFetching: provinceLoading } =
    useLookupCountryCountryIdSubdivisionGet(country as string, {
      query: {
        enabled: !!country
      }
    });

  const provincesOptions = useMemo(() => {
    if (provinces) {
      return provinces.data.map((province) => ({
        value: province.subdivisionId as string,
        label: province.name as string
      }));
    }
    return [];
  }, [provinces]);

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    console.log(addressComponents);
    setValue(`ownerDetails[${index}].address1`, address1);
    if (subpremise)
      setValue(`ownerDetails[${index}].address2`, subpremise?.long_name);
    setValue(`ownerDetails[${index}].country`, country?.short_name);
    const countryAltId = countryOptions.find(
      (c) => c.countryId === country?.short_name
    )?.countryAltId;
    setValue(`ownerDetails[${index}].countryAltId`, countryAltId);
    setValue(`ownerDetails[${index}].state`, state?.short_name);
    setValue(`ownerDetails[${index}].city`, city?.long_name);
    setValue(`ownerDetails[${index}].zip`, zip?.long_name);
    trigger(`ownerDetails[${index}].country`);
    trigger(`ownerDetails[${index}].country`);
    trigger(`ownerDetails[${index}].state`);
    trigger(`ownerDetails[${index}].city`);
    trigger(`ownerDetails[${index}].zip`);
  };
  return (
    <>
      <Grid xs={6}>
        <FormSelect
          control={control}
          name={`ownerDetails[${index}].country`}
          label="Country"
          required
          options={countryOptions}
          isLoading={isFetchingCountries}
          rules={{
            required: "Country is required"
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid xs={6}>
        <AddressAutocomplete
          name={`ownerDetails[${index}].address1`}
          control={control}
          label="Address line 1"
          required
          rules={{
            required: "Address line 1 is required"
          }}
          selectSuggestion={selectAddressSuggestion}
          disabled={disabled}
          trigger={trigger}
        />
      </Grid>
      <Grid xs={6}>
        <FormInput
          type="text"
          name={`ownerDetails[${index}].address2`}
          control={control}
          label="Address line 2"
          disabled={disabled}
        />
      </Grid>
      <Grid xs={6}>
        <FormInput
          type="text"
          name={`ownerDetails[${index}].city`}
          control={control}
          label="City"
          required
          rules={{
            required: "City is required"
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid xs={6}>
        <FormSelect
          name={`ownerDetails[${index}].state`}
          control={control}
          rules={{ required: "State is required" }}
          label="State"
          options={provincesOptions}
          type="text"
          required
          isLoading={provinceLoading}
          disabled={disabled}
        />
      </Grid>
      <Grid xs={6}>
        <FormInput
          name={`ownerDetails[${index}].zip`}
          control={control}
          rules={{ required: "Zip is required" }}
          label="Zip"
          type="text"
          disabled={disabled}
          required
        />
      </Grid>
    </>
  );
};
