import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { IconButton } from "@mui/material";
import { Control } from "react-hook-form";
import LaunchIcon from "@mui/icons-material/Launch";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useState } from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { DeleteIcon } from "@components/Icons";

export type RelatedLink = { url: string; name: string; otherName: string };
export const RelatedLinks = (props: {
  relatedLinks: RelatedLink[];
  setRelatedLinks?: (links: RelatedLink[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  disabled?: boolean;
}) => {
  const [toDelete, setToDelete] = useState<
    | {
        index: number;
        link: RelatedLink;
      }
    | undefined
  >(undefined);

  const websiteClick = (website) => {
    if (website.includes("http://") || website.includes("https://"))
      window.open(website, "_blank");
    else window.open("https://" + website, "_blank");
  };
  return (
    <>
      {props.relatedLinks.map((rl, idx) => {
        return (
          <>
            <Grid xs={6}>
              <FormInput
                control={props.control}
                name={`url${idx}`}
                label="URL"
                required
                enteredValue={rl.url}
                type="text"
                disabled={props.disabled}
                onChange={(e) => {
                  const links = props.relatedLinks;
                  links[idx].url = e!.target!.value;
                  if (props.setRelatedLinks) props.setRelatedLinks([...links]);
                }}
                rules={{
                  required: "URL is required"
                }}
                InputProps={{
                  ...(props.disabled && {
                    endAdornment: (
                      <IconButton onClick={() => websiteClick(rl.url)}>
                        <LaunchIcon style={{ color: "#007AFF" }} />
                      </IconButton>
                    )
                  })
                }}
              />
            </Grid>
            <Grid xs={2.75}>
              <FormSelect
                name={`urlType${idx}`}
                label="Name"
                required
                value={rl.name}
                options={[
                  {
                    label: "Facebook",
                    value: "FACEBOOK"
                  },
                  {
                    label: "Instagram",
                    value: "INSTAGRAM"
                  },
                  {
                    label: "Twitter",
                    value: "TWITTER"
                  },
                  {
                    label: "Youtube",
                    value: "YOUTUBE"
                  },
                  {
                    label: "Linkedin",
                    value: "LINKEDIN"
                  },
                  {
                    label: "Other",
                    value: "OTHER"
                  }
                ]}
                onChange={(e) => {
                  const links = props.relatedLinks;
                  links[idx].name = e!.target!.value;
                  if (props.setRelatedLinks) props.setRelatedLinks([...links]);
                }}
                disabled={props.disabled}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid xs={2.75}>
              {rl.name === "OTHER" && (
                <FormInput
                  name={`urlOtherName${idx}`}
                  label="Other Name"
                  required
                  enteredValue={rl.otherName}
                  rules={{
                    required: "Other Name is required"
                  }}
                  type="text"
                  control={props.control}
                  disabled={props.disabled}
                  onChange={(e) => {
                    const links = props.relatedLinks;
                    links[idx].otherName = e!.target!.value;
                    if (props.setRelatedLinks)
                      props.setRelatedLinks([...links]);
                  }}
                />
              )}
            </Grid>
            {props.relatedLinks.length > 1 && !props.disabled && (
              <Grid xs={0.5}>
                <IconButton
                  style={{ marginTop: "25px" }}
                  onClick={() => {
                    setToDelete({ index: idx, link: rl });
                  }}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </Grid>
            )}
          </>
        );
      })}
      <ConfirmationDialog
        open={!!toDelete}
        title="Delete Related Link"
        body={`Are you sure you want to delete related link ${
          toDelete?.link.url ? `"${toDelete.link.url}"` : ""
        } attached to this account?`}
        close={() => setToDelete(undefined)}
        onConfirm={() => {
          const links = props.relatedLinks;
          links.splice(toDelete?.index as number, 1);
          const value = props.control._formValues;
          for (let i = 0; i <= props.relatedLinks.length; i++) {
            delete value[`url${i}`];
            delete value[`urlType${i}`];
            delete value[`urlOtherName${i}`];
          }
          links.map((link, index) => {
            value[`url${index}`] = link.url;
            value[`urlType${index}`] = link.name;
            value[`urlOtherName${index}`] = link.otherName;
          });
          props.control._reset(value);
          if (props.setRelatedLinks) props.setRelatedLinks([...links]);
        }}
        onCancel={() => setToDelete(undefined)}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </>
  );
};
