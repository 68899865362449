/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactPlayer from "react-player";
import { Backdrop, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { FileDownloadOutlined } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
export const AttachmentMediaViewer = ({
  media,
  onClose
}: {
  media: ModelMedia;
  onClose;
}) => {
  const StyledBox = styled(Box)(({ theme }) => ({
    marginLeft: "256px",
    marginTop: "50px",
    position: "fixed",
    backgroundColor: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "646px",
    height: "554px",
    zIndex: 9999,
    overflow: "hidden",
    borderRadius: "5px",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "20%"
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px"
    }
  }));

  const buttonStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    padding: "10px",
    border: "none",

    width: "100px",
    height: "89px",
    cursor: "pointer"
  };
  const StyledDocViewer = styled(DocViewer)`
    #pdf-controls {
      visibility: hidden;
    }
  `;

  const downloadFile = async (url: string, fileName: string) => {
    if (media.mimeType === "application/pdf") {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName; // You can change the filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        enqueueSnackbar("Error downloading the file");
      }
    } else {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <StyledBox
        data-testid="feed-media-viewer"
        style={
          media.type === "DOCUMENT"
            ? {
                height: "80%",
                width: "100%"
              }
            : {}
        }
      >
        {media.type === "IMAGE" ? (
          <img
            src={media.baseUrl! + media.path!}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              position: "relative",
              margin: "auto"
            }}
            alt="Image"
          />
        ) : media.type === "VIDEO" ? (
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  disablePictureInPicture: true
                }
              }
            }}
            playing={true}
            controls
            height={"100%"}
            width={"100%"}
            url={media.baseUrl! + media.path!}
          />
        ) : (
          <StyledDocViewer
            config={{
              header: {
                disableHeader: true,
                disableFileName: false,
                retainURLParams: false
              },

              pdfVerticalScrollByDefault: true // false as default
            }}
            documents={[
              {
                uri: media.baseUrl! + media.path!,
                ...(media.metadata?.fileName && {
                  fileName: media.metadata.fileName
                }),
                ...(media.mimeType === "application/pdf" && {
                  fileType: "pdf"
                })
              }
            ]}
            style={{
              height: "100%",
              ...(media.mimeType === "application/pdf" && {
                overflow: "auto"
              })
            }}
            prefetchMethod="GET"
            pluginRenderers={DocViewerRenderers}
            className=""
          />
        )}

        <CloseIcon
          onClick={onClose}
          sx={{
            ...buttonStyle,
            top: 30,
            right: 0,
            height: "50px",
            width: "95px",
            zIndex: 100000000,
            color: media.type === "DOCUMENT" ? "grey" : "white"
          }}
        />
        <IconButton
          style={{
            position: "absolute",
            transform: "translateY(-50%)",
            padding: "10px",
            top: 60,
            right: 0,
            height: "50px",
            width: "95px",
            zIndex: 100000000,
            marginTop: "20px"
          }}
          onClick={() => {
            downloadFile(
              media.baseUrl! + media.path,
              media.metadata?.fileName || "download"
            );
          }}
        >
          <FileDownloadOutlined
            htmlColor={media.type === "DOCUMENT" ? "grey" : "white"}
          />
        </IconButton>
        {media.type === "VIDEO" && (
          <div
            style={{
              position: "absolute",
              transform: "translateY(-50%)",
              padding: "10px",
              top: 60,
              left: 0,
              zIndex: 100000000,
              marginLeft: "10px"
            }}
          >
            <p
              style={{
                color: "white",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 600
              }}
            >
              {media.metadata?.fileName || "Video"}
            </p>
          </div>
        )}
      </StyledBox>
    </Backdrop>
  );
};
