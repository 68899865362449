import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { PresetTagForm } from "./PresetTagForm";
import { Loader } from "@components/crud/Loader";
import {
  ModelPresetTag,
  useAdminPresetTagPresetTagIdDelete,
  useAdminPresetTagPresetTagIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { enqueueSnackbar } from "notistack";

export const PresetTagView = () => {
  const navigate = useNavigate();
  const { presetTagId } = useParams();
  const [tagToDelete, setTagToDelete] = useState<ModelPresetTag | null>(null);

  const form = useForm({
    mode: "onBlur"
  });
  const { reset } = form;
  const { data: presetTag, isLoading: isLoading } =
    useAdminPresetTagPresetTagIdGet(presetTagId as string);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminPresetTagPresetTagIdDelete();

  const onConfirmDelete = async () => {
    if (!tagToDelete?.presetTagId) return;
    try {
      await deleteAsync({ presetTagId: tagToDelete.presetTagId });
      enqueueSnackbar("Tag deleted successfully", { variant: "success" });
      setTagToDelete(null);
      navigate("/preset-tags");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete tag.", {
        variant: "error"
      });
      setTagToDelete(null);
    }
  };

  useEffect(() => {
    if (presetTag?.data) {
      const tag = presetTag.data;
      reset({
        name: tag.name,
        isActive: tag.isActive ? "true" : "false",
        sport: tag.sportId
      });
    }
  }, [presetTag]);

  return (
    <Container>
      <Loader isLoading={isLoading}>
        <Toolbar
          title="View Clip Pre-set Tag"
          editBtnClick={() => navigate(`/preset-tags/${presetTagId}/edit`)}
          backBtnClick={() => navigate("/preset-tags")}
          deleteBtnClick={() =>
            setTagToDelete(presetTag?.data as ModelPresetTag)
          }
        />

        <FormProvider {...form}>
          <PresetTagForm disabled />
        </FormProvider>
      </Loader>
      <ConfirmationDialog
        open={!!tagToDelete}
        title="Delete Preset Tag?"
        body={`Are you sure you want to delete ${tagToDelete?.name}?`}
        close={() => setTagToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setTagToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
