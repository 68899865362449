import React, { ChangeEvent, useEffect, useMemo } from "react";
import { Container } from "@components/crud/Container";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { FormSelect } from "@components/FormSelect";
import { MERCHANT_ACCOUNT_TYPES } from "@utils/constants";
import { FormInput } from "@components/FormInput";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormCheckbox } from "@components/FormCheckbox";
import { EMAIL_REGEX, WEBSITE_REGEX } from "@utils/validation";
import { useLookupCountryCountryIdSubdivisionGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { LoadingSpinner } from "@components/LoadingSpinner";

const libraries = ["places"] as "places"[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autocompleteService = { current: null } as any;

export const AboutBuisness = ({ disabled }: { disabled: boolean }) => {
  const { control, setValue, trigger, watch } = useFormContext();
  const [addressSelected, setAddressSelected] = React.useState<boolean>(true);
  const [selectedCountry, setSelectedCountry] = React.useState<string | null>();
  const country = watch("country");
  const buissnessType = watch("type");
  const acceptCreditCard = watch("acceptCreditCard");
  const { data: provinces, isFetching: provinceLoading } =
    useLookupCountryCountryIdSubdivisionGet(
      country === "USA" ? "US" : country === "CAN" ? "CA" : country,
      {
        query: {
          enabled: !!country
        }
      }
    );

  const provincesOptions = useMemo(() => {
    if (provinces) {
      return provinces.data.map((province) => ({
        value: province.subdivisionId as string,
        label: province.name as string
      }));
    }
    return [];
  }, [provinces]);
  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = `${streetNumber?.long_name} ${route?.long_name}`;
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    setValue(`address1`, address1);
    if (subpremise) setValue(`address2`, subpremise?.long_name);
    if (country?.short_name == "CA") setValue(`country`, "CAN");
    if (country?.short_name == "US") setValue(`country`, "USA");
    if (country?.short_name === "US" || country?.short_name === "CA")
      setValue(`state`, state?.short_name);
    setSelectedCountry(country?.short_name);
    setValue(`city`, city?.long_name);
    setValue(`zip`, zip?.long_name);
    trigger(`address1`);
    trigger(`country`);
    trigger(`state`);
    trigger(`city`);
    trigger(`zip`);
  };
  useEffect(() => {
    if (trigger) {
      if (addressSelected) {
        trigger("address1");
      }
    }
  }, [addressSelected]);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="type"
            label="Business Type"
            options={MERCHANT_ACCOUNT_TYPES}
            required
            rules={{
              required: "Business Type is required"
            }}
            disabled={disabled}
          />
        </Grid>
        {buissnessType === "0" ? (
          <Grid xs={6}>
            <FormSelect
              control={control}
              name="haveEmployees"
              label="Does this business have employee"
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: 0 }
              ]}
              required
              rules={{
                required: "This field is required"
              }}
              disabled={disabled}
            />
          </Grid>
        ) : (
          <Grid xs={6}>
            <FormInput
              label="Legal Business Name"
              name="name"
              type="text"
              control={control}
              required
              rules={{
                required: "Legal Business Name is required"
              }}
              disabled={disabled}
            />
          </Grid>
        )}

        {["1", "2", "3"].includes(buissnessType) && (
          <Grid xs={12}>
            <FormCheckbox
              control={control}
              name="public"
              label="Public Company"
              disabled={disabled}
            />
          </Grid>
        )}

        <Grid xs={6}>
          <FormInput
            type="text"
            control={control}
            name="dba"
            label="DBA - Statement Descriptor"
            required
            rules={{
              required: "DBA - Statement Descriptor is required"
            }}
            disabled={disabled}
            onChange={(e) => {
              setValue(
                "sdLabel",
                `SPL*${(e as ChangeEvent<HTMLInputElement>).target.value}`
              );
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            type="text"
            control={control}
            name="sdLabel"
            label="Preview"
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            type="tel"
            control={control}
            name="customerPhone"
            label="Customer Service Phone"
            required
            rules={{
              required: "Customer Service Phone is required"
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormDatePicker
            control={control}
            name="established"
            label="Date of Establishment"
            required
            rules={{
              required: "Date of Establishment is required"
            }}
            disabled={disabled}
            trigger={trigger}
            disableFuture
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            type="text"
            control={control}
            name="globalBusinessId"
            label="Tax ID Number"
            required
            rules={{
              required: "Tax ID Number is required",
              validate: (value) => value.length === 9 || "Invalid Tax ID Number"
            }}
            TextProps={{
              onInput: (e) => {
                (e as ChangeEvent<HTMLInputElement>).target.value = (
                  e as ChangeEvent<HTMLInputElement>
                ).target.value.replace(/[^0-9]/g, "");
              },
              inputProps: {
                maxLength: 9
              }
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            type="text"
            control={control}
            name="avgTicket"
            label="Average Transaction Amount"
            required
            rules={{
              required: "Average Transaction Amount is required"
            }}
            InputProps={{
              startAdornment: (
                <div style={{ color: "#666", paddingRight: "10px" }}>$</div>
              )
            }}
            TextProps={{
              onInput: (e) => {
                const input = e as ChangeEvent<HTMLInputElement>;
                input.target.value = input.target.value.replace(/[^0-9.]/g, "");
                const parts = input.target.value.split(".");
                if (parts.length > 2) {
                  input.target.value = parts[0] + "." + parts.slice(1).join("");
                }
              }
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormCheckbox
            control={control}
            name="acceptCreditCard"
            label="Currently Accept Credit Card"
            onChange={(e) => {
              if (e.target.checked) {
                setValue("environment", "cardPresent");
              } else {
                setValue("environment", undefined);
              }
            }}
            disabled={disabled}
          />
        </Grid>
        {acceptCreditCard && (
          <Grid xs={6}>
            <FormInput
              type="text"
              control={control}
              name="annualCCSales"
              label="Average Processing Volume"
              required
              rules={{
                required: "Average Processing Volume is required"
              }}
              InputProps={{
                startAdornment: (
                  <div style={{ color: "#666", paddingRight: "10px" }}>$</div>
                )
              }}
              TextProps={{
                onInput: (e) => {
                  const input = e as ChangeEvent<HTMLInputElement>;
                  input.target.value = input.target.value.replace(
                    /[^0-9.]/g,
                    ""
                  );
                  const parts = input.target.value.split(".");
                  if (parts.length > 2) {
                    input.target.value =
                      parts[0] + "." + parts.slice(1).join("");
                  }
                }
              }}
              disabled={disabled}
            />
          </Grid>
        )}
        <Grid xs={2}>
          <FormSelect
            name="phoneType"
            label="Work Phone"
            options={[{ label: "Mobile", value: "MOBILE" }]}
            defaultValue={"MOBILE"}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid xs={4}>
          <span>&nbsp;</span>
          <FormInput
            type="tel"
            control={control}
            name="phone"
            label=""
            disabled={disabled}
            rules={{
              required: "Phone is required"
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            type="tel"
            control={control}
            name="fax"
            label="FAX"
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            type="email"
            control={control}
            name="email"
            label="Email"
            required
            rules={{
              required: "Email is required",
              validate: (value) =>
                EMAIL_REGEX.test(value) || "Invalid email address"
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            type="text"
            control={control}
            name="website"
            label="Website"
            disabled={disabled}
            required
            rules={{
              required: "Website is required",
              validate: (value) =>
                WEBSITE_REGEX.test(value) || "Invalid website address"
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="country"
            label="Country"
            required
            options={[
              { label: "United States", value: "USA" },
              { label: "Canada", value: "CAN" }
            ]}
            rules={{
              required: "Country is required"
            }}
            disabled={disabled}
          />
        </Grid>
        <LoadScript
          key="address"
          googleMapsApiKey={import.meta.env.VITE_APP_MAP_API_KEY as string}
          libraries={libraries}
          loadingElement={<LoadingSpinner />}
        >
          <Grid xs={6}>
            <Autocomplete
              key={"address1"}
              className="address-autocomplete"
              onLoad={(autocomplete) => {
                autocompleteService.current = autocomplete;
              }}
              onPlaceChanged={() => {
                if (autocompleteService.current) {
                  const place = autocompleteService.current.getPlace();
                  selectAddressSuggestion(place);
                  setAddressSelected(true);
                }
              }}
            >
              <FormInput
                name="address1"
                control={control}
                rules={{
                  required: "Address is required",
                  validate: () => {
                    if (!addressSelected) {
                      return "The address entered is invalid, please make a recommended selection";
                    }
                    if (selectedCountry !== "US" && selectedCountry !== "CA") {
                      return "Address should be in USA or CAN";
                    }
                    return undefined;
                  }
                }}
                label="Address line 1"
                required
                type="text"
                onChange={() => {
                  setAddressSelected(false);
                }}
                disabled={disabled}
              />
            </Autocomplete>
          </Grid>
        </LoadScript>
        <Grid xs={6}>
          <FormInput
            type="text"
            name="address2"
            control={control}
            label="Address line 2"
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            type="text"
            name="city"
            control={control}
            label="City"
            required
            rules={{
              required: "City is required"
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            name="state"
            control={control}
            rules={{ required: "State is required" }}
            label="State"
            options={provincesOptions}
            type="text"
            required
            isLoading={provinceLoading}
            disabled={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <FormInput
            name="zip"
            control={control}
            rules={{ required: "Zip is required" }}
            label="Zip"
            type="text"
            required
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
