/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IconButton, styled } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { useContext } from "react";
import {
  ModelAccount,
  ModelAccountContact,
  useGetAdminCrmAccountAccountIdContact
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import dayjs from "dayjs";
import { createCRMActivity } from "@services/Network";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import timezone from "dayjs/plugin/timezone";
import { enqueueSnackbar } from "notistack";

const StyledBox = styled("div")`
  position: absolute;
  bottom: 0;
  right: 32px;
  width: 450px;
  padding: 24px 24px 40px 24px;
  border-radius: 8px 0;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 24px 0px #00000029;
  background-color: #ffffff;
  z-index: 10000;
  min-height: 300px;
`;

const StyledOrgName = styled("div")`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
  width: 100%;
  text-align: center;
`;

const StyledOrgLocation = styled("div")`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #666;
  width: 100%;
  text-align: center;
  margin-top: 6px;
  width: calc(100% - 24px);
`;

const ContactListBox = styled("div")`
  margin-top: 24px;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;
export const AccountContactList = ({ account }: { account: ModelAccount }) => {
  dayjs.extend(timezone);
  const user = useRecoilValue(profileAtom);
  const { data: contacts, isLoading: isContactsLoading } =
    useGetAdminCrmAccountAccountIdContact(account.accountId as string);
  const {
    setAccount,
    connectToCall,
    setCallAccepted,
    setCallerDetails,
    callInstance
  } = useContext(CallEmailTemplateContext);
  const mutation = createCRMActivity();

  const Contact = ({ contact }: { contact: ModelAccountContact }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "10px"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div
            style={{ display: "flex", alignItems: "flex-start", gap: "6px" }}
          >
            <div
              style={{
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#000"
              }}
            >
              {contact.contact?.firstName + " " + contact.contact?.lastName}
            </div>
            {contact.isPrimary && (
              <StarRateRoundedIcon
                style={{ height: "16px", width: "16px", color: "#E82C2C" }}
              />
            )}
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              color: "#666"
            }}
          >
            {contact.contact?.phone}
          </div>
        </div>
        {contact?.contact?.phone && (
          <div
            style={{
              height: "50px",
              minWidth: "120px",
              backgroundColor: "#1ABC9C",
              borderRadius: "99px",
              padding: "8px 12px",
              paddingLeft: "18px"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer"
              }}
              onClick={async () => {
                if (callInstance) {
                  enqueueSnackbar("You are already on a call!", {
                    variant: "error"
                  });
                  return;
                }
                if (contact.accountId)
                  mutation.mutate(
                    {
                      data: {
                        accountId: contact.accountId,
                        type: "CALL",
                        relatesTo: "ACCOUNT",
                        date: new Date(),
                        timezone: dayjs.tz.guess(),
                        direction: "outbound",
                        status: "LIVE",
                        outcome: "CONNECTED_WITH_CONTACT",
                        internalParticipants: [user?.userId as string],
                        externalParticipants: [
                          {
                            contactId: contact.contactId,
                            phone: contact.contact?.phone || ""
                          }
                        ]
                      }
                    },
                    {
                      onSuccess: (data) => {
                        setCallerDetails({
                          activityId: data?.data?.activity?.activityId,
                          accountId: contact.accountId,
                          accountName: account?.name,
                          callDirection: "outbound",
                          location: account.officeAddress,
                          phone: contact.contact?.phone,
                          email: contact.contact?.email,
                          isConnected: contact.accountId ? true : false,
                          contact: {
                            contactName:
                              contact.contact?.firstName +
                              " " +
                              contact.contact?.lastName,
                            contactPhone: contact.contact?.phone,
                            isPrimary: contact.isPrimary
                          }
                        });
                        connectToCall(
                          contact?.contact?.phone as string,
                          data?.data?.activity?.activityId
                        );
                        setAccount(undefined);
                        setCallAccepted(true);
                      }
                    }
                  );
              }}
            >
              <CallIcon
                style={{ height: "32px", width: "32px", color: "#fff" }}
              />
              <span
                style={{ fontSize: "20px", color: "#fff", fontWeight: 500 }}
              >
                Call
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <StyledBox>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between"
        }}
      >
        <StyledOrgName>{account?.name}</StyledOrgName>
        <IconButton
          style={{ padding: 0 }}
          onClick={() => setAccount(undefined)}
        >
          <CloseRoundedIcon
            style={{ height: "24px", width: "24px", color: "#000" }}
          />
        </IconButton>
      </div>
      <StyledOrgLocation>{account?.officeAddress}</StyledOrgLocation>
      <Loader isLoading={isContactsLoading || mutation.isLoading}>
        <ContactListBox>
          {contacts?.data?.map((contact) => (
            <Contact contact={contact} key={contact.contactId} />
          ))}
        </ContactListBox>
      </Loader>
    </StyledBox>
  );
};
