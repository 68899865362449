import { Container } from "@components/crud/Container";
import { RenderTableView } from "@components/RenderTableView";
import { ToolTip } from "@components/ToolTip";
import { Add, Edit, Visibility } from "@mui/icons-material";
import { Box, IconButton, styled, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Button } from "@components/Button";
import { SearchInput } from "@components/SearchInput";
import { useContext, useEffect, useState } from "react";
import { NoRecords } from "@components/NoRecords";
import formatFullName from "@utils/formatFullName";
import { useNavigate } from "react-router-dom";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import {
  ModelAccount,
  ModelAccountContact,
  ModelPerson
} from "@sportsgravyengineering/sg-api-react-sdk";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import timezone from "dayjs/plugin/timezone";
import { GridColDef } from "@mui/x-data-grid";
import { enqueueSnackbar } from "notistack";
import { createCRMActivity } from "@services/Network";
import { Loader } from "@components/crud/Loader";
import GoogleMap from "@components/GoogleMap";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const StyledBox = styled(Box)`
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledLink = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AccountContacts = ({
  contacts,
  onClickAdd,
  account
}: {
  contacts;
  onClickAdd: () => void;
  account: ModelAccount;
}) => {
  dayjs.extend(timezone);
  const user = useRecoilValue(profileAtom);
  const { setCallerDetails, connectToCall, setCallAccepted, callInstance } =
    useContext(CallEmailTemplateContext);
  const mutation = createCRMActivity();
  const [addressToView, setAddressToView] = useState<string | undefined>(
    undefined
  );
  const [textSearch, setTextSearch] = useState("");
  const [rows, setRows] = useState(contacts);

  useEffect(() => {
    const filteredRows = contacts.filter((row) => {
      const searchTerms = textSearch.toLowerCase().split(" ");
      const nameMatches = (name, term) =>
        name && name.toLowerCase().includes(term);

      return searchTerms.every(
        (term) =>
          nameMatches(row.contact.firstName, term) ||
          nameMatches(row.contact.middleName, term) ||
          nameMatches(row.contact.lastName, term)
      );
    });
    setRows(filteredRows);
  }, [textSearch, contacts]);
  const { setEmailTo } = useContext(CallEmailTemplateContext);
  const navigate = useNavigate();
  const CONTACT_COLUMNS: GridColDef<ModelAccountContact>[] = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderHeader: () => {
        return <div style={{ padding: "20px 20px" }}>Action</div>;
      },
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 20px", display: "flex" }}>
            <IconButton
              onClick={() => {
                navigate(`/crm/contacts/${params.row.contactId}`);
              }}
            >
              <ToolTip title="View Contact" placement="top">
                <Visibility style={IconStyle} />
              </ToolTip>
            </IconButton>
            <IconButton
              onClick={() => {
                navigate(`/crm/contacts/${params.row.contactId}/edit`);
              }}
            >
              <ToolTip title="Edit Contact" placement="top">
                <Edit style={IconStyle} />
              </ToolTip>
            </IconButton>
            <Loader isLoading={mutation.isLoading}>
              <IconButton
                disabled={!params.row.contact?.phone}
                onClick={async () => {
                  const contact = params.row;
                  if (callInstance) {
                    enqueueSnackbar("You are already on a call!", {
                      variant: "error"
                    });
                    return;
                  }
                  mutation.mutate(
                    {
                      data: {
                        accountId: account.accountId,
                        type: "CALL",
                        relatesTo: "ACCOUNT",
                        date: new Date(),
                        timezone: dayjs.tz.guess(),
                        direction: "outbound",
                        status: "LIVE",
                        outcome: "CONNECTED_WITH_CONTACT",
                        internalParticipants: [user?.userId as string],
                        externalParticipants: [
                          {
                            contactId: contact.contactId,
                            phone: contact?.contact?.phone || ""
                          }
                        ]
                      }
                    },
                    {
                      onSuccess: (data) => {
                        setCallerDetails({
                          activityId: data?.data?.activity?.activityId,
                          contactId: contact.contactId,
                          accountId: account.accountId,
                          accountName: account.name,
                          callDirection: "outbound",
                          location: account.officeAddress,
                          phone: contact?.contact?.phone,
                          isConnected: account.accountId ? true : false,
                          contact: {
                            contactName:
                              contact?.contact?.firstName +
                              " " +
                              contact?.contact?.lastName,
                            contactPhone: contact?.contact?.phone,
                            isPrimary: contact?.isPrimary
                          }
                        });
                        connectToCall(
                          contact?.contact?.phone as string,
                          data?.data?.activity?.activityId
                        );
                        setCallAccepted(true);
                      }
                    }
                  );
                }}
              >
                <ToolTip title="Click to Call" placement="top-start">
                  <CallIcon style={IconStyle} />
                </ToolTip>
              </IconButton>
            </Loader>
            <IconButton
              disabled={!params.row.contact?.email}
              onClick={() => {
                setEmailTo({
                  to: params.row.contact?.email,
                  name: formatFullName(
                    params.row.contact as {
                      firstName: string;
                      middleName: string;
                      lastName: string;
                      suffix: string;
                    }
                  ),
                  accountId: account?.accountId,
                  relatesTo: "CONTACT",
                  contactId: params.row.contact?.contactId
                });
              }}
            >
              <ToolTip title="Click to Email" placement="top-start">
                <EmailIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
          </div>
        );
      }
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => formatFullName(row.contact as ModelPerson)
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      flex: 1,
      minWidth: 250,
      valueGetter: ({ row }) => row.jobTitle || "-"
    },
    {
      field: "location",
      headerName: "Work Location",
      flex: 1,
      sortable: false,
      minWidth: 350,
      valueGetter: ({ row }) => row.contact?.location,
      renderCell: ({ row }) => {
        if (row.contact?.location)
          return (
            <StyledLink onClick={() => setAddressToView(row.contact?.location)}>
              {row.contact?.location}
            </StyledLink>
          );
        return "-";
      }
    },
    {
      field: "isPrimary",
      headerName: "Is Primary",
      flex: 1,
      sortable: false,
      minWidth: 150,
      valueFormatter: ({ value }) => (value ? "Yes" : "No")
    }
  ];
  return (
    <Container>
      <StyledBox>
        <div>
          <Typography
            style={{
              color: "#1E293B",
              fontWeight: 300,
              fontSize: "32px"
            }}
          >
            Contacts
          </Typography>
          <Button
            variant="admin-primary"
            startIcon={<Add style={{ height: "27px", width: "27px" }} />}
            onClick={onClickAdd}
          >
            Add
          </Button>
        </div>
        <SearchInput
          required={false}
          placeholder="Search"
          onChange={(e) => setTextSearch(e.target.value)}
        />
      </StyledBox>
      <RenderTableView
        title=""
        hideToolbar
        rows={rows}
        columns={CONTACT_COLUMNS}
        getRowId={(row) => row.contactId}
        hasActionColumn={false}
        hideFooter
        sortingMode="client"
        getRowHeight={() => "auto"}
        noRecordsFoundElemet={
          <NoRecords
            title="The list is empty"
            description="Add contacts related to this account"
            buttonClick={onClickAdd}
            buttonText="Add Contact"
            buttonIcon={<Add />}
          />
        }
        pinnedColumns={{
          left: ["action", "name"]
        }}
      />
      {addressToView && (
        <GoogleMap
          close={() => setAddressToView(undefined)}
          address={addressToView}
          showSearchBar={false}
          showClickedAddress={false}
        />
      )}
    </Container>
  );
};
