import { Tier } from "@pages/settings/CRMSetting";

export const getCostPerAthlete = (
  numberOfAthletes: number,
  pricingTiers: Tier[],
  getSetupCost = false
) => {
  if (!isNaN(numberOfAthletes)) {
    for (const tier of pricingTiers) {
      const startAthlete = parseInt(tier.startAthlete, 10);
      const endAthlete = tier.endAthlete
        ? parseInt(tier.endAthlete, 10)
        : Infinity;

      if (numberOfAthletes >= startAthlete && numberOfAthletes <= endAthlete) {
        return getSetupCost
          ? {
              cost: parseFloat(tier.price) || 0,
              setupCost: parseFloat(tier.setupPrice) || 0
            }
          : parseFloat(tier.price);
      }
    }
  }
  return 0;
};
