// ALWAYS keep this file sorted alphabetically by component name
import { Route, Routes as DomRoutes } from "react-router-dom";

import { AccountInfo } from "@pages/sign-up/AccountInfo";
import { Accounts } from "@pages/crm/accounts/Accounts";
import { AccountAdd } from "@pages/crm/accounts/AccountAdd";
import { AccountEdit } from "@pages/crm/accounts/AccountEdit";
import { AccountOverview } from "@pages/crm/accounts/AccountOverview";
import { ActivityAdd } from "@pages/crm/activities/ActivityAdd";
import { ActivityEdit } from "@pages/crm/activities/ActivityEdit";
import { ActivityView } from "@pages/crm/activities/ActivityView";
import { Advertisers } from "@pages/advertiser/Advertisers";
import { AdvertiserCreate } from "@pages/advertiser/AdvertiserCreate";
import { AdvertiserEdit } from "@pages/advertiser/AdvertiserEdit";
import { AdvertiserView } from "@pages/advertiser/AdvertiserView";
import { AdvertiserInvoice } from "@pages/advertiser-invoice/AdvertiserInvoice";
import { AdvertiserInvoiceView } from "@pages/advertiser-invoice/AdvertiserInvoiceView";
import { AdvertisementApproval } from "@pages/advertiser/advertisements/AdvertisementApproval";
import { AdvertisementCreate } from "@pages/advertiser/advertisements/AdvertismentCreate";
import { AdvertisementEdit } from "@pages/advertiser/advertisements/AdvertisementEdit";
import { AdvertisementView } from "@pages/advertiser/advertisements/AdvertisementView";
import { AuthenticatedTemplate } from "@templates/Authenticated";
import { BookDemoSession } from "@pages/sign-up/BookDemoSession";
import { BasicInfo } from "@pages/sign-up/BasicInfo";
import { CalendarView } from "@pages/calendar/CalendarView";
import { CallEmailTemplate } from "@templates/CallEmailTemplate";
import { CalendarImportEvents } from "@pages/calendar/CalendarImportEvents";
import { CannedMessageCreate } from "@pages/canned-messages/CannedMessageCreate";
import { CannedMessageEdit } from "@pages/canned-messages/CannedMessageEdit";
import { CannedMessageView } from "@pages/canned-messages/CannedMessageView";
import { CannedMessages } from "@pages/canned-messages/CannedMessages";
import { Dashboard } from "@pages/dashboard/Dashboard";
import { Departments } from "@pages/departments/departments";
import { DepartmentView } from "@pages/departments/departmentView";
import { DepartmentCreate } from "@pages/departments/departmentCreate";
import { DepartmentEdit } from "@pages/departments/departmentEdit";
import { Directory } from "@pages/directory/directory";
import { DuplicateAccountAssociatedEmail } from "@pages/sign-up/DuplicateAccountAssociatedEmail";
import { DuplicateAccountAssociatedPhone } from "@pages/sign-up/DuplicateAccountAssociatedPhone";
import { DuplicateAccountWarning } from "@pages/sign-up/DuplicateAccountWarning";
import { DuplicatePendingAccountWarning } from "@pages/sign-up/DuplicatePendingAccountWarning";
import { Faq } from "@pages/faqs/Faq";
import { FaqCreate } from "@pages/faqs/FaqCreate";
import { FaqEdit } from "@pages/faqs/FaqEdit";
import { FaqView } from "@pages/faqs/FaqView";
import { Feed } from "@pages/feed/Feed";
import { Features } from "@pages/features/Features";
import { FeatureEdit } from "@pages/features/FeatureEdit";
import { FeatureCreate } from "@pages/features/FeatureCreate";
import { FeatureView } from "@pages/features/FeatureView";
import { FeedbackReply } from "@pages/advertiser/FeedbackReply";
import { FixFailedRow } from "@pages/import-players/FixFailedRow";
import { ForgotPassword } from "@pages/auth/ForgotPassword";
import { GameConceptCreate } from "@pages/game-concepts/GameConceptCreate";
import { GameConceptEdit } from "@pages/game-concepts/GameConceptEdit";
import { GameConceptView } from "@pages/game-concepts/GameConceptView";
import { GameConcepts } from "@pages/game-concepts/GameConcepts";
import { GameSystemCreate } from "@pages/game-systems/GameSystemCreate";
import { GameSystemEdit } from "@pages/game-systems/GameSystemEdit";
import { GameSystemView } from "@pages/game-systems/GameSystemView";
import { GameSystems } from "@pages/game-systems/GameSystems";
import { GanttChart } from "@pages/gantt-chart/GanttChart";
import { HelpArticles } from "@pages/helpArticle/HelpArticles";
import { HelpArticleCreate } from "@pages/helpArticle/HelpArticleCreate";
import { HelpArticleView } from "@pages/helpArticle/HelpArticleView";
import { HelpArticleEdit } from "@pages/helpArticle/HelpArticleEdit";
import { HelpCategory } from "@pages/help-category/HelpCategory";
import { HelpCategoryCreate } from "@pages/help-category/HelpCategoryCreate";
import { HelpCategoryEdit } from "@pages/help-category/HelpCategoryEdit";
import { HelpCategoryView } from "@pages/help-category/HelpCategoryView";
import { ImportPlayer } from "@pages/import-players/ImportPlayer";
import { ImportPlayerCreate } from "@pages/import-players/ImportPlayerCreate";
import { ImportPlayerView } from "@pages/import-players/ImportPlayerView";
import { ImportTemplateProviderCreate } from "@pages/import-template-providers/ImportTemplateProviderCreate";
import { ImportTemplateProviderEdit } from "@pages/import-template-providers/ImportTemplateProviderEdit";
import { ImportTemplateProviderVersionCreate } from "@pages/import-template-providers/ImportTemplateProviderVersionCreate";
import { ImportTemplateProviderVersionView } from "@pages/import-template-providers/ImportTemplateProviderVersionView";
import { ImportTemplateProviderView } from "@pages/import-template-providers/ImportTemplateProviderView";
import { ImportTemplateProviders } from "@pages/import-template-providers/ImportTemplateProviders";
import { Invite } from "@pages/invite/Invite";
import { JobTitles } from "@pages/jobTitles/jobTitles";
import { JobTitleView } from "@pages/jobTitles/jobTitleView";
import { JobTitleCreate } from "@pages/jobTitles/jobTitleCreate";
import { JobTitleEdit } from "@pages/jobTitles/jobTitleEdit";
import { KidConsent } from "@pages/sign-up/InviteKids/KidConsent";
import { KidSelect } from "@pages/sign-up/InviteKids/KidSelect";
import { LevelCreate } from "@pages/levels/LevelCreate";
import { LevelEdit } from "@pages/levels/LevelEdit";
import { LevelView } from "@pages/levels/LevelView";
import { Levels } from "@pages/levels/Levels";
import { LiveStream } from "@pages/live-stream/LiveStream";
import { LiveStream as ShareOutSideLiveStream } from "@pages/share-outside/LiveStream";
import { LiveStreamCreate } from "@pages/live-stream/LiveStreamCreate";
import { LiveStreamEdit } from "@pages/live-stream/LiveStreamEdit";
import { LiveStreamView } from "@pages/live-stream/LiveStreamView";
import { Login } from "@pages/auth/Login";
import { Media } from "@pages/share-outside/Media";
import { Merchant } from "@pages/merchant/Merchant";
import NotFound from "@pages/NotFound";
import { OrganizationCreate } from "@pages/organizations/OrganizationCreate";
import { OrganizationEdit } from "@pages/organizations/OrganizationEdit";
import { OrganizationView } from "@pages/organizations/OrganizationView";
import { Organizations } from "@pages/organizations/Organizations";
import { PendingAccountWarning } from "@pages/sign-up/PendingAccountWarning";
import { PersonalInfo } from "@pages/sign-up/PersonalInfo";
import { PositionCreate } from "@pages/positions/PositionCreate";
import { PositionEdit } from "@pages/positions/PositionEdit";
import { PositionView } from "@pages/positions/PositionView";
import { Positions } from "@pages/positions/Positions";
import { Post } from "@pages/share-outside/Post";
import { Profile } from "@pages/profile/Profile";
import { ProgramCreate } from "@pages/teams-programs/training-programs/ProgramCreate";
import { ProgramEdit } from "@pages/teams-programs/training-programs/ProgramEdit";
import { Programs } from "@pages/teams-programs/training-programs/Programs";
import { ProgramsView } from "@pages/teams-programs/training-programs/ProgramsView";
import { PresetTags } from "@pages/preset-tags/PresetTags";
import { PresetTagCreate } from "@pages/preset-tags/PresetTagCreate";
import { PresetTagEdit } from "@pages/preset-tags/PresetTagEdit";
import { PresetTagView } from "@pages/preset-tags/PresetTagView";
import { PresetTagSportSort } from "@pages/preset-tags/PresetTagSportSort";
import { QAFeatures } from "@pages/qa-features/qaFeatures";
import { QAFeatureView } from "@pages/qa-features/QAFeatureView";
import { QAFeatureEdit } from "@pages/qa-features/QAFeatureEdit";
import { QATestcases } from "@pages/qa-testcase/QaTestcases";
import { QATestcaseEdit } from "@pages/qa-testcase/QaTestcaseEdit";
import { QATestcaseView } from "@pages/qa-testcase/QaTestcaseView";
import { Report } from "@pages/report/Report";
import { Releases } from "@pages/releases/Releases";
import { ReleaseCreate } from "@pages/releases/ReleaseCreate";
import { ReleaseEdit } from "@pages/releases/ReleaseEdit";
import { ReleaseView } from "@pages/releases/ReleaseView";
import { ReportDetailView } from "@pages/report/ReportDetailView";
import { ReportTableView } from "@pages/report/ReportTableView";
import { ResetPassword } from "@pages/auth/ResetPassword";
import { ResetPasswordVerify } from "@pages/auth/ResetPasswordVerify";
import { ResourceView } from "@pages/directory/directoryView";
import { RoleEdit } from "@pages/roles/RoleEdit";
import { RoleView } from "@pages/roles/RoleView";
import { Roles } from "@pages/roles/Roles";
import { SeasonCreate } from "@pages/seasons/SeasonCreate";
import { SeasonEdit } from "@pages/seasons/SeasonEdit";
import { SeasonView } from "@pages/seasons/SeasonView";
import { Seasons } from "@pages/seasons/Seasons";
import { SelectPrimaryAccount } from "@pages/sign-up/SelectPrimaryAccount";
import { Settings } from "@pages/settings/Settings";
import { SignupProvider } from "@components/SignupProvider";
import { Skills } from "@pages/skills/Skills";
import { SkillsCreate } from "@pages/skills/SkillsCreate";
import { SkillsEdit } from "@pages/skills/SkillsEdit";
import { SkillsView } from "@pages/skills/SkillsView";
import { SponsorshipLevel } from "@pages/sponsorship-level/SponsorshipLevel";
import { SponsorshipLevelCreate } from "@pages/sponsorship-level/SponsorshipLevelCreate";
import { SponsorshipLevelEdit } from "@pages/sponsorship-level/SponsorshipLevelEdit";
import { SponsorshipLevelView } from "@pages/sponsorship-level/SponsorshipLevelView";
import { SportCountrySort } from "@pages/sports/SportCountrySort";
import { SportCreate } from "@pages/sports/SportCreate";
import { SportEdit } from "@pages/sports/SportEdit";
import { SportView } from "@pages/sports/SportView";
import { Sports } from "@pages/sports/Sports";
import { SubroleCreate } from "@pages/subroles/SubroleCreate";
import { SubroleEdit } from "@pages/subroles/SubroleEdit";
import { SubroleView } from "@pages/subroles/SubroleView";
import { Subroles } from "@pages/subroles/Subroles";
import { SubscriptionPlans } from "@pages/sign-up/SubscriptionPlans";
import { Success } from "@pages/sign-up/Success";
import { SportLocations } from "@pages/sport-location/SportLocations";
import { SportLocationCreate } from "@pages/sport-location/SportLocationCreate";
import { SportLocationEdit } from "@pages/sport-location/SportLocationEdit";
import { SportLocationView } from "@pages/sport-location/SportLoctionView";
import { TeamCreate } from "@pages/teams-programs/teams/TeamCreate";
import { TeamEdit } from "@pages/teams-programs/teams/TeamEdit";
import { TeamView } from "@pages/teams-programs/teams/TeamView";
import { Teams } from "@pages/teams-programs/teams/Teams";
import { TermsOfUse } from "@pages/sign-up/TermsOfUse/TermsOfUse";
import { Testcases } from "@pages/testcase/Testcases";
import { TestcaseCreate } from "@pages/testcase/TestcaseCreate";
import { TestcaseEdit } from "@pages/testcase/TestcaseEdit";
import { TestcaseView } from "@pages/testcase/TestcaseView";
import { UnauthenticatedTemplate } from "@templates/Unauthenticated";
import { UserAdd } from "@pages/users/UserCreate";
import { UserEdit } from "@pages/users/UserEdit";
import { UserView } from "@pages/users/UserView";
import { Users } from "@pages/users/Users";
import { VerifyAccount } from "@pages/sign-up/VerifyAccount";
import { WhoopsyDaisy } from "@pages/auth/WhoopsyDaisy";
import { Contacts } from "@pages/crm/contacts/Contacts";
import { CreateContact } from "@pages/crm/contacts/CreateContact";
import { ContactView } from "@pages/crm/contacts/ContactView";
import { ContactEdit } from "@pages/crm/contacts/ContactEdit";
import { LeadCreate } from "@pages/crm/leads/LeadCreate";
import { LeadView } from "@pages/crm/leads/LeadView";
import { LeadEdit } from "@pages/crm/leads/LeadEdit";
import { Opportunities } from "@pages/crm/opportunities/Opportunities";
import { OpportunityCreate } from "@pages/crm/opportunities/OpportunityCreate";
import { Leads } from "@pages/crm/leads/Leads";
import { OpportunityEdit } from "@pages/crm/opportunities/OpportunityEdit";
import { OpportunityView } from "@pages/crm/opportunities/OpportunityView";
import { Activities } from "@pages/crm/activities/Activities";
import { KeyData } from "@pages/crm/key-data/KeyData";
import { KeyDataEdit } from "@pages/crm/key-data/KeyDataEdit";
import { TwilioPhoneNumber } from "@pages/crm/twilio-phone-number/TwilioPhoneNumber";
import { Orders } from "@pages/crm/orders/orders";
import { OrderCreate } from "@pages/crm/orders/OrderCreate";
import { OrderView } from "@pages/crm/orders/OrderView";
import { OrderEdit } from "@pages/crm/orders/OrderEdit";
import { PaymentDetailsCreate } from "@pages/crm/orders/PaymentDetailsCreate";
import { SignContract } from "@pages/crm/orders/SignContract";
import InvoicePayment from "@pages/advertiser-invoice/InvoicePayment";
import { EmailTemplates } from "@pages/email-templates/emailTemplates";
import { EmailTemplateCreate } from "@pages/email-templates/EmailTemplateCreate";
import { EmailTemplateView } from "@pages/email-templates/EmailTemplateview";
import { EmailTemplateEdit } from "@pages/email-templates/EmailTemplateEdit";

export default function Routes(): JSX.Element {
  return (
    <DomRoutes>
      <Route path="/media/shared/:compressedUrl" element={<Media />} />
      <Route path="/post/shared/:compressedUrl" element={<Post />} />
      <Route
        path="/live-stream/shared/:compressedUrl"
        element={<ShareOutSideLiveStream />}
      />
      <Route
        path="sign-contract/:orderId/:compressedEmail"
        element={<SignContract />}
      />
      <Route path="/invoice/payment/:invoiceId" element={<InvoicePayment />} />
      <Route path="/" element={<UnauthenticatedTemplate />}>
        <Route
          path="auth/invite/:inviteId/:compressedEmail"
          element={<Invite />}
        />
        <Route
          path="advertiser/:advertiserId/advertisement/:advertisementId/:compressEmail"
          element={<AdvertisementApproval />}
        />
        <Route path="sign-up" element={<SignupProvider />}>
          <Route path="basic-info" element={<BasicInfo />} />
          <Route path="personal-info" element={<PersonalInfo />} />
          <Route path="account-info" element={<AccountInfo />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="verify-account" element={<VerifyAccount />} />
          <Route path="subscription-plans" element={<SubscriptionPlans />} />
          <Route path="success" element={<Success />} />
          <Route path="kid-invite" element={<KidSelect />} />
          <Route path="kid-consent" element={<KidConsent />} />
          <Route
            path="duplicate-account-associated-email"
            element={<DuplicateAccountAssociatedEmail />}
          />
          <Route
            path="duplicate-account-associated-phone"
            element={<DuplicateAccountAssociatedPhone />}
          />
          <Route
            path="duplicate-account-warning"
            element={<DuplicateAccountWarning />}
          />
          <Route
            path="duplicate-pending-account-warning"
            element={<DuplicatePendingAccountWarning />}
          />
          <Route
            path="pending-account-warning"
            element={<PendingAccountWarning />}
          />
          <Route
            path="select-primary-account"
            element={<SelectPrimaryAccount />}
          />
        </Route>
        <Route path="book-appointment" element={<BookDemoSession />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route
          path="reset-password-verify/:b64Email/:totp"
          element={<ResetPasswordVerify />}
        />
        <Route
          path="reset-password/:b64Email/:totp"
          element={<ResetPassword />}
        />
        <Route path="unauthorized" element={<WhoopsyDaisy />} />
        <Route path="" element={<Login />} />
        <Route path="not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/" element={<AuthenticatedTemplate />}>
        <Route path="canned-messages">
          <Route path="" element={<CannedMessages />} />
          <Route path="create" element={<CannedMessageCreate />} />
          <Route path=":messageId">
            <Route path="" element={<CannedMessageView />} />
            <Route path="edit" element={<CannedMessageEdit />} />
          </Route>
        </Route>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="departments">
          <Route path="" element={<Departments />} />
          <Route path="create" element={<DepartmentCreate />} />
          <Route path=":departmentId">
            <Route path="" element={<DepartmentView />} />
            <Route path="edit" element={<DepartmentEdit />} />
          </Route>
        </Route>
        <Route path="directory">
          <Route path="" element={<Directory />} />
          <Route path=":userId">
            <Route path="" element={<ResourceView />} />
          </Route>
        </Route>
        <Route path="email-templates">
          <Route path="" element={<EmailTemplates />} />
          <Route path="create" element={<EmailTemplateCreate />} />
          <Route path=":templateId">
            <Route path="" element={<EmailTemplateView />} />
            <Route path="edit" element={<EmailTemplateEdit />} />
          </Route>
        </Route>
        <Route path="features">
          <Route path="" element={<Features />} />
          <Route path="create" element={<FeatureCreate />} />
          <Route path=":featureId">
            <Route path="" element={<FeatureView />} />
            <Route path="edit" element={<FeatureEdit />} />
            <Route path="create" element={<FeatureCreate />} />
          </Route>
        </Route>
        <Route path="feed">
          <Route path="" element={<Feed />} />
          <Route path=":feedId" element={<Feed />} />
        </Route>
        <Route path="game-concepts">
          <Route path="" element={<GameConcepts />} />
          <Route path="create" element={<GameConceptCreate />} />
          <Route path=":conceptId">
            <Route path="" element={<GameConceptView />} />
            <Route path="edit" element={<GameConceptEdit />} />
          </Route>
        </Route>
        <Route path="game-systems">
          <Route path="" element={<GameSystems />} />
          <Route path="create" element={<GameSystemCreate />} />
          <Route path=":systemId">
            <Route path="" element={<GameSystemView />} />
            <Route path="edit" element={<GameSystemEdit />} />
          </Route>
        </Route>
        <Route path="help-articles">
          <Route path="" element={<HelpArticles />} />
          <Route path="create" element={<HelpArticleCreate />} />
          <Route path=":articleId">
            <Route path="" element={<HelpArticleView />} />
            <Route path="edit" element={<HelpArticleEdit />} />
          </Route>
        </Route>
        <Route path="import-template-providers">
          <Route path="" element={<ImportTemplateProviders />} />
          <Route path="create" element={<ImportTemplateProviderCreate />} />
          <Route path=":providerId">
            <Route path="" element={<ImportTemplateProviderView />} />
            <Route
              path="create"
              element={<ImportTemplateProviderVersionCreate />}
            />
            <Route path="edit" element={<ImportTemplateProviderEdit />} />
            <Route
              path=":versionId"
              element={<ImportTemplateProviderVersionView />}
            />
          </Route>
        </Route>
        <Route path="job-titles">
          <Route path="" element={<JobTitles />} />
          <Route path="create" element={<JobTitleCreate />} />
          <Route path=":jobTitleId">
            <Route path="" element={<JobTitleView />} />
            <Route path="edit" element={<JobTitleEdit />} />
          </Route>
        </Route>
        <Route path="levels">
          <Route path="" element={<Levels />} />
          <Route path="create" element={<LevelCreate />} />
          <Route path=":levelId">
            <Route path="" element={<LevelView />} />
            <Route path="edit" element={<LevelEdit />} />
          </Route>
        </Route>
        <Route path="seasons">
          <Route path="" element={<Seasons />} />
          <Route path="create" element={<SeasonCreate />} />
          <Route path=":seasonId">
            <Route path="" element={<SeasonView />} />
            <Route path="edit" element={<SeasonEdit />} />
          </Route>
        </Route>
        <Route path="organizations">
          <Route path="" element={<Organizations />} />
          <Route path="create" element={<OrganizationCreate />} />
          <Route path=":organizationId">
            <Route path="" element={<OrganizationView />} />
            <Route path="edit" element={<OrganizationEdit />} />
          </Route>
        </Route>
        <Route path="positions">
          <Route path="" element={<Positions />} />
          <Route path="create" element={<PositionCreate />} />
          <Route path=":positionId">
            <Route path="" element={<PositionView />} />
            <Route path="edit" element={<PositionEdit />} />
          </Route>
        </Route>
        <Route path="profile" element={<Profile />} />
        <Route path="qa-features">
          <Route path="" element={<QAFeatures />} />
          <Route path=":featureId">
            <Route path="" element={<QAFeatureView />} />
            <Route path="edit" element={<QAFeatureEdit />} />
          </Route>
        </Route>
        <Route path="roles">
          <Route path="" element={<Roles />} />
          <Route path=":roleId">
            <Route path="" element={<RoleView />} />
            <Route path="edit" element={<RoleEdit />} />
          </Route>
        </Route>
        <Route path="reports">
          <Route path="" element={<Report />} />
          <Route path=":filter/:type">
            <Route path="" element={<ReportTableView />} />
            <Route path=":id" element={<ReportDetailView />} />
          </Route>
        </Route>
        <Route path="settings" element={<Settings />} />
        <Route path="skills">
          <Route path="" element={<Skills />} />
          <Route path="create" element={<SkillsCreate />} />
          <Route path=":skillId">
            <Route path="" element={<SkillsView />} />
            <Route path="edit" element={<SkillsEdit />} />
          </Route>
        </Route>
        <Route path="sports">
          <Route path="" element={<Sports />} />
          <Route path="country/:countryId" element={<SportCountrySort />} />
          <Route path="create" element={<SportCreate />} />
          <Route path=":sportId">
            <Route path="" element={<SportView />} />
            <Route path="edit" element={<SportEdit />} />
          </Route>
        </Route>
        <Route path="subroles">
          <Route path="" element={<Subroles />} />
          <Route path="create" element={<SubroleCreate />} />
          <Route path=":roleId">
            <Route path="" element={<SubroleView />} />
            <Route path="edit" element={<SubroleEdit />} />
          </Route>
        </Route>
        <Route path="teams">
          <Route path="" element={<Teams />} />
          <Route path="create" element={<TeamCreate />} />
          <Route path=":teamId">
            <Route path="" element={<TeamView />} />
            <Route path="edit" element={<TeamEdit />} />
          </Route>
        </Route>
        <Route path="training-programs">
          <Route path="" element={<Programs />} />
          <Route path="create" element={<ProgramCreate />} />
          <Route path=":programId">
            <Route path="" element={<ProgramsView />} />
            <Route path="edit" element={<ProgramEdit />} />
          </Route>
        </Route>
        <Route path="users">
          <Route path="" element={<Users />} />
          <Route path="create" element={<UserAdd />} />
          <Route path=":userId">
            <Route path="" element={<UserView />} />
            <Route path="edit" element={<UserEdit />} />
          </Route>
        </Route>
        <Route path="feed">
          <Route path="" element={<Feed />} />
          <Route path=":feedId" element={<Feed />} />
        </Route>
        <Route path="reports">
          <Route path="" element={<Report />} />
          <Route path=":filter/:type">
            <Route path="" element={<ReportTableView />} />
            <Route path=":id" element={<ReportDetailView />} />
          </Route>
        </Route>
        <Route path="live-stream">
          <Route path="" element={<LiveStream />} />
          <Route path="create" element={<LiveStreamCreate />} />
          <Route path=":id">
            <Route path="" element={<LiveStreamView />} />
            <Route path="edit" element={<LiveStreamEdit />} />
          </Route>
        </Route>
        <Route path="import-players">
          <Route path="" element={<ImportPlayer />} />
          <Route path="create" element={<ImportPlayerCreate />} />
          <Route path=":uploadId">
            <Route path="" element={<ImportPlayerView />} />
            <Route path="edit/:fixId" element={<FixFailedRow />} />
          </Route>
        </Route>
        <Route path="sponsorship-levels">
          <Route path="" element={<SponsorshipLevel />} />
          <Route path="create" element={<SponsorshipLevelCreate />} />
          <Route path=":id">
            <Route path="" element={<SponsorshipLevelView />} />
            <Route path="edit/" element={<SponsorshipLevelEdit />} />
          </Route>
        </Route>
        <Route path="advertisers">
          <Route path="" element={<Advertisers />} />
          <Route path="create" element={<AdvertiserCreate />} />
          <Route path=":advertiserId">
            <Route path="" element={<AdvertiserView />} />
            <Route path="edit/" element={<AdvertiserEdit />} />
            <Route path="advertisement">
              <Route path="create" element={<AdvertisementCreate />} />
              <Route path=":advertisementId">
                <Route path="" element={<AdvertisementView />} />
                <Route path="edit" element={<AdvertisementEdit />} />
                <Route path=":feedbackId/reply" element={<FeedbackReply />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="sport-locations">
          <Route path="" element={<SportLocations />} />
          <Route path="create" element={<SportLocationCreate />} />
          <Route path=":locationId">
            <Route path="" element={<SportLocationView />} />
            <Route path="edit" element={<SportLocationEdit />} />
          </Route>
        </Route>
        <Route path="calendar">
          <Route path="" element={<CalendarView />} />
          <Route path="import-events" element={<CalendarImportEvents />} />
        </Route>
        <Route path="testcases">
          <Route path="" element={<Testcases />} />
          <Route path="create" element={<TestcaseCreate />} />
          <Route path=":testcaseId">
            <Route path="" element={<TestcaseView />} />
            <Route path="create" element={<TestcaseCreate />} />
            <Route path="edit" element={<TestcaseEdit />} />
          </Route>
        </Route>
        <Route path="help-category">
          <Route path="" element={<HelpCategory />} />
          <Route path="create" element={<HelpCategoryCreate />} />
          <Route path=":helpCategoryId">
            <Route path="" element={<HelpCategoryView />} />
            <Route path="edit" element={<HelpCategoryEdit />} />
          </Route>
        </Route>
        <Route path="faqs">
          <Route path="" element={<Faq />} />
          <Route path="create" element={<FaqCreate />} />
          <Route path=":faqId">
            <Route path="" element={<FaqView />} />
            <Route path="edit" element={<FaqEdit />} />
          </Route>
        </Route>
        <Route path="releases">
          <Route path="" element={<Releases />} />
          <Route path="create" element={<ReleaseCreate />} />
          <Route path=":releaseId">
            <Route path="" element={<ReleaseView />} />
            <Route path="edit" element={<ReleaseEdit />} />
          </Route>
        </Route>
        <Route path="qa-testcases">
          <Route path="" element={<QATestcases />} />
          <Route path=":testcaseId">
            <Route path="" element={<QATestcaseView />} />
            <Route path="edit" element={<QATestcaseEdit />} />
          </Route>
        </Route>
        <Route path="gantt-chart" element={<GanttChart />} />
        <Route path="crm" element={<CallEmailTemplate />}>
          <Route path="accounts">
            <Route path="" element={<Accounts />} />
            <Route path="create" element={<AccountAdd />} />
            <Route path=":accountId">
              <Route path="" element={<AccountOverview />} />
              <Route path="edit" element={<AccountEdit />} />
            </Route>
          </Route>
          <Route path="leads">
            <Route path="" element={<Leads />} />
            <Route path="create" element={<LeadCreate />} />
            <Route path=":leadId">
              <Route path="" element={<LeadView />} />
              <Route path="edit" element={<LeadEdit />} />
            </Route>
          </Route>
          <Route path="contacts">
            <Route path="" element={<Contacts />} />
            <Route path="create" element={<CreateContact />} />
            <Route path=":contactId">
              <Route path="" element={<ContactView />} />
              <Route path="edit" element={<ContactEdit />} />
            </Route>
          </Route>
          <Route path="activities">
            <Route path="" element={<Activities />} />
            <Route path="create" element={<ActivityAdd />} />
            <Route path=":activityId">
              <Route path="" element={<ActivityView />} />
              <Route path="edit" element={<ActivityEdit />} />
            </Route>
          </Route>
          <Route path="opportunities">
            <Route path="" element={<Opportunities />} />
            <Route path="create" element={<OpportunityCreate />} />
            <Route path=":opportunityId">
              <Route path="" element={<OpportunityView />} />
              <Route path="edit" element={<OpportunityEdit />} />
            </Route>
          </Route>
          <Route path="orders">
            <Route path="" element={<Orders />} />
            <Route path="create" element={<OrderCreate />} />
            <Route path=":orderId">
              <Route path="" element={<OrderView />} />
              <Route path="edit" element={<OrderEdit />} />
              <Route path="paymentDetail">
                <Route path="create" element={<PaymentDetailsCreate />} />
              </Route>
            </Route>
          </Route>
          <Route path="key-data">
            <Route path="" element={<KeyData />} />
            <Route path=":accountId">
              <Route path="edit" element={<KeyDataEdit />} />
            </Route>
          </Route>
          <Route path="twilio-phone-number">
            <Route path="" element={<TwilioPhoneNumber />} />
          </Route>
        </Route>
        <Route path="merchant-account" element={<Merchant />} />
        <Route path="preset-tags">
          <Route path="" element={<PresetTags />} />
          <Route path="sport/:sportId" element={<PresetTagSportSort />} />
          <Route path="create" element={<PresetTagCreate />} />
          <Route path=":presetTagId">
            <Route path="" element={<PresetTagView />} />
            <Route path="edit" element={<PresetTagEdit />} />
          </Route>
        </Route>
        <Route path="advertiser-invoices">
          <Route path="" element={<AdvertiserInvoice />} />
          <Route path=":invoiceId">
            <Route path="" element={<AdvertiserInvoiceView />} />
          </Route>
        </Route>
      </Route>
    </DomRoutes>
  );
}
