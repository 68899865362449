import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { hasPermission } from "@services/Casbin";
import { getCrmAccounts } from "@services/Network";
import {
  ModelAccount,
  ModelContact,
  ModelPerson
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WebsiteIcon from "@mui/icons-material/Language";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton, Typography } from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { ToolTip } from "@components/ToolTip";
import GoverningBodyIcon from "@assets/icons/governingBody.svg";
import { QuickAddAccount } from "./components/QuickAddAccount";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { CRM_ACCOUNT_CATEGORIES, CRM_ACCOUNT_TYPES } from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import { VirtualMeetingIcon } from "@components/Icons";
import { CreateMeetingModal } from "../components/CreateMeetingModal";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";

const IconStyle = {
  height: "20px",
  width: "20px"
};

export const Accounts = () => {
  const user = useRecoilValue(profileAtom);
  const { setAccount, setEmailTo } = useContext(CallEmailTemplateContext);
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const [quickAdd, setQuickAdd] = useState(false);
  const [showMeeting, setShowMeeting] = useState(false);
  const [selectedMeetingAccount, setSelectedMeetingAccount] = useState<
    string | undefined
  >(undefined);
  const [selectedMeetingContact, setSelectedMeetingContact] = useState<
    ModelContact | undefined
  >();
  const onAdd = () => navigate("create");
  const onQuickAdd = () => setQuickAdd(true);
  const onEdit = (account) =>
    navigate(`/crm/accounts/${account.accountId}/edit`);
  const onView = (account) =>
    navigate(`/crm/accounts/${account.accountId}?tab=Account+Details`);
  const onAccountDashboardView = (account) => navigate(`${account.accountId}`);
  const onQuickAddClose = () => setQuickAdd(false);

  const ACCOUNTS_COLUMNS: GridColDef<ModelAccount>[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: 300,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const primaryContactName = formatFullName(
          params.row.contacts?.[0]?.contact as ModelPerson
        );
        return (
          <div style={{ padding: "20px 0" }}>
            <IconButton onClick={() => onAccountDashboardView(params.row)}>
              <ToolTip
                title="Click for Account Dashboard"
                placement="top-start"
              >
                <SpaceDashboardIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
            {permissions.view && (
              <IconButton onClick={() => onView(params.row)}>
                <ToolTip title="View Account" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton onClick={() => onEdit(params.row)}>
                <ToolTip title="Edit Account" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            <IconButton onClick={() => setAccount(params.row)}>
              <ToolTip
                // <ToolTip
                title={`Click to Call ${
                  primaryContactName || "Primary Contact"
                }`}
                placement="top-start"
              >
                <CallIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
            <IconButton
              onClick={() => {
                setShowMeeting(true);
                setSelectedMeetingAccount(params.row.accountId);
                setSelectedMeetingContact(params.row.contacts?.[0]?.contact);
              }}
            >
              <ToolTip
                // <ToolTip
                title={`Click to Start Meeting`}
                placement="top-start"
              >
                <VirtualMeetingIcon
                  style={{
                    width: "25px",
                    height: "25px"
                  }}
                />
              </ToolTip>
            </IconButton>
            <IconButton
              onClick={() => {
                setEmailTo({
                  to: params.row.email,
                  name: params.row.name,
                  accountId: params.row.accountId,
                  relatesTo: "ACCOUNT"
                });
              }}
            >
              <ToolTip
                title={`Click to Email ${
                  primaryContactName || "Primary Contact"
                }`}
                placement="top-start"
              >
                <EmailIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
            <IconButton
              onClick={() => {
                const website = params.row.website || "";
                if (website.includes("http://") || website.includes("https://"))
                  window.open(website, "_blank");
              }}
            >
              <ToolTip title="Click to Visit Website" placement="top">
                <WebsiteIcon style={IconStyle} />
              </ToolTip>
            </IconButton>
          </div>
        );
      }
    },
    {
      headerName: "Name",
      field: "name",
      renderHeader: () => <div style={{ paddingLeft: "3px" }}>Name</div>,
      minWidth: 175,
      flex: 1,
      renderCell: (params) => {
        if (params.row.parentId) {
          const parent = params.row.parent;
          if (parent)
            return (
              <ToolTip
                title={
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        navigate(`/crm/accounts/${parent.accountId}`)
                      }
                    >
                      <Typography color="#007AFF">{parent.name}</Typography>
                      <IconButton>
                        <LaunchIcon
                          style={{
                            color: "#007AFF",
                            height: "14px",
                            width: "14px"
                          }}
                        />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px"
                      }}
                    >
                      <Typography color="#64748B" variant="body2">
                        {`${CRM_ACCOUNT_CATEGORIES.find(
                          (c) => c.value === parent.category
                        )?.label} • ${CRM_ACCOUNT_TYPES.find(
                          (c) => c.value === parent.type
                        )?.label}`}
                      </Typography>
                      <Typography color="#64748B" variant="body2">
                        {parent.officeAddress}
                      </Typography>
                    </div>
                  </div>
                }
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>{params.row.name}</span>
                  <span>
                    <img src={GoverningBodyIcon} />
                  </span>
                </div>
              </ToolTip>
            );
        }
        return <div>{params.row.name}</div>;
      }
    },
    {
      headerName: "Category",
      field: "category",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        CRM_ACCOUNT_CATEGORIES.find((c) => c.value === row.category)?.label
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        CRM_ACCOUNT_TYPES.find((c) => c.value === row.type)?.label
    },
    {
      headerName: "Primary Contact",
      field: "contact",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span>
              {" "}
              {formatFullName(params.row.contacts?.[0]?.contact as ModelPerson)}
            </span>
            {params.row.contacts && params.row.contacts.length > 1 && (
              <ToolTip
                title={params.row.contacts
                  .slice(1)
                  .map((p) => formatFullName(p.contact as ModelPerson))
                  .join(", ")}
                placement="top-start"
              >
                <span style={{ marginLeft: "7px", color: "#007AFF" }}>
                  {" "}
                  {` +${params.row.contacts.length - 1}`}
                </span>
              </ToolTip>
            )}
          </>
        );
      }
      //valueGetter: ({ row }) => formatFullName(row.contacts?.[0]?.contact)
    },
    {
      headerName: "Location",
      field: "location",
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row }) => row.officeAddress
    },
    {
      headerName: "BDR Owner",
      field: "bdrOwner",
      minWidth: 250,
      flex: 1,
      renderCell: ({ row }) =>
        `${formatFullName(row.bdrOwner?.person as ModelPerson)} ${
          row.aeOwnerId === user?.userId ? "(You)" : ""
        }`
    },
    {
      headerName: "AE Owner",
      field: "aeOwner",
      minWidth: 250,
      flex: 1,
      renderCell: ({ row }) =>
        `${formatFullName(row.aeOwner?.person as ModelPerson)} ${
          row.aeOwnerId === user?.userId ? "(You)" : ""
        }`
    },
    {
      headerName: "CSM Owner",
      field: "csmOwner",
      minWidth: 250,
      flex: 1,
      renderCell: ({ row }) =>
        `${formatFullName(row.csmOwner?.person as ModelPerson)} ${
          row.aeOwnerId === user?.userId ? "(You)" : ""
        }`
    }
  ];

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.accounts", "ADD");
      const edit = await checkPermission("crm.accounts", "EDIT");
      const view = await checkPermission("crm.accounts", "VIEW");
      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      {
        label: "My Accounts",
        value: "mine"
      }
    ]
  };
  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Accounts"
          useGet={getCrmAccounts}
          columns={ACCOUNTS_COLUMNS}
          getRowId={(row) => row.accountId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          onAdd={permissions?.create ? onAdd || undefined : undefined}
          quickAdd={permissions?.create ? onQuickAdd || undefined : undefined}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          getRowHeight={() => "auto"}
          hasActionColumn={false}
          pinnedColumns={{ left: ["action", "name"] }}
          rowSelection={false}
        />
      </Loader>
      {quickAdd && (
        <QuickAddAccount
          onClose={onQuickAddClose}
          onSave={(res) => {
            const rk = refreshKey;
            setRefreshKey(rk + 1);
            if (res)
              navigate(`/crm/accounts/${res.accountId}?tab=Account+Details`);
          }}
        />
      )}
      {showMeeting && selectedMeetingAccount && (
        <CreateMeetingModal
          open={showMeeting}
          setOpen={setShowMeeting}
          selectedAccount={selectedMeetingAccount}
          selectedContact={selectedMeetingContact}
        />
      )}
    </>
  );
};
