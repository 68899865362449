import { useContext, useMemo, useState } from "react";
import { Typography, styled } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { FormSelect } from "@components/FormSelect";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FaxIcon from "@assets/icons/faxIcon.svg";
import CopyIcon from "@assets/icons/copyIcon.svg";
import { ToolTip } from "@components/ToolTip";
import { CallEmailTemplateContext } from "@templates/CallEmailTemplate";
import { createCRMActivity } from "@services/Network";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import {
  ModelAccount,
  ModelActivityRelatesTo,
  ModelContact,
  ModelOpportunityAccount
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";

const StyledLink = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTitle = styled("div")`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
`;

const StyledValue = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  margin-top: 4px;
`;

const FlexBox = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconStyle = {
  height: "20px",
  width: "20px",
  color: "#666666"
};

export const ContactDetails = ({
  account,
  contactDetails,
  contactSelected,
  relatesTo = "ACCOUNT",
  relatesToId
}: {
  account: ModelAccount | ModelOpportunityAccount;
  contactDetails: {
    contact: ModelContact;
    isPrimary: boolean;
  }[];
  contactSelected?: (string: string) => void;
  relatesTo?: ModelActivityRelatesTo;
  relatesToId?: string;
}) => {
  dayjs.extend(timezone);
  const user = useRecoilValue(profileAtom);
  const { setCallerDetails, connectToCall, setCallAccepted, callInstance } =
    useContext(CallEmailTemplateContext);
  const [selectedContact, setSelectedContact] = useState<{
    contact: ModelContact;
    isPrimary: boolean;
  }>({
    contact: contactDetails?.[0]?.contact || null,
    isPrimary: contactDetails?.[0]?.isPrimary
  });
  const contactOptions = useMemo(() => {
    if (!contactDetails) return [];
    return contactDetails?.map((contact) => ({
      label:
        contact.contact.firstName +
        " " +
        contact.contact.lastName +
        (contact.isPrimary ? " - Primary Contact" : ""),
      value: contact.contact.contactId
    }));
  }, [contactDetails]);

  const { setEmailTo } = useContext(CallEmailTemplateContext);
  const mutation = createCRMActivity();

  const copyToClipboard = (text, type) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        enqueueSnackbar(`${type} copied to clipboard`, {
          variant: "success"
        });
      });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        const successful = document.execCommand("copy");
        if (successful)
          enqueueSnackbar(`${type} copied to clipboard`, {
            variant: "success"
          });
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textarea);
    }
  };

  if (selectedContact) {
    return (
      <div
        style={{ marginTop: "24px" }}
        key={selectedContact?.contact?.contactId}
      >
        <FormSelect
          value={selectedContact?.contact?.contactId}
          name="contact"
          options={contactOptions}
          onChange={(e) => {
            const contact = contactDetails.find(
              (contact) => contact.contact.contactId == e.target.value
            );
            if (contactSelected) contactSelected(contact?.contact?.contactId);

            if (contact)
              setSelectedContact({
                contact: contact.contact,
                isPrimary: contact.isPrimary
              });
          }}
        />
        {selectedContact.contact.phone && (
          <FlexBox>
            <StyledTitle>
              <CallOutlinedIcon style={IconStyle} />
              <span>Phone</span>
            </StyledTitle>
            <Loader isLoading={mutation.isLoading} align="flex-end">
              <StyledLink style={{ marginTop: "10px" }}>
                <ToolTip title="Click to Call" placement="top">
                  <span
                    onClick={async () => {
                      if (callInstance) {
                        enqueueSnackbar("You are already on a call!", {
                          variant: "error"
                        });
                        return;
                      }
                      mutation.mutate(
                        {
                          data: {
                            accountId: account.accountId,
                            type: "CALL",
                            relatesTo: relatesTo,
                            ...(relatesTo === "OPPORTUNITY" &&
                              relatesToId && {
                                opportunityId: relatesToId
                              }),
                            date: new Date(),
                            timezone: dayjs.tz.guess(),
                            direction: "outbound",
                            status: "LIVE",
                            outcome: "CONNECTED_WITH_CONTACT",
                            internalParticipants: [user?.userId as string],
                            externalParticipants: [
                              {
                                contactId: selectedContact.contact.contactId,
                                phone: selectedContact.contact.phone || ""
                              }
                            ]
                          }
                        },
                        {
                          onSuccess: (data) => {
                            setCallerDetails({
                              activityId: data?.data?.activity?.activityId,
                              contactId: selectedContact.contact.contactId,
                              accountId: account.accountId,
                              accountName: account.name,
                              callDirection: "outbound",
                              relatesTo: relatesTo,
                              ...(relatesTo === "OPPORTUNITY" &&
                                relatesToId && {
                                  opportunityId: relatesToId
                                }),
                              location: account.officeAddress,
                              phone: selectedContact.contact.phone,
                              isConnected: account.accountId ? true : false,
                              contact: {
                                contactName:
                                  selectedContact.contact.firstName +
                                  " " +
                                  selectedContact.contact.lastName,
                                contactPhone: selectedContact.contact.phone,
                                isPrimary: selectedContact.isPrimary
                              }
                            });
                            connectToCall(
                              selectedContact.contact.phone as string,
                              data?.data?.activity?.activityId
                            );
                            setCallAccepted(true);
                          }
                        }
                      );
                    }}
                  >
                    {selectedContact.contact.phone}
                  </span>
                </ToolTip>
                <ToolTip title="Copy Phone to Clipboard" placement="top">
                  <img
                    src={CopyIcon}
                    style={IconStyle}
                    onClick={() =>
                      copyToClipboard(selectedContact.contact.phone, "Phone")
                    }
                  />
                </ToolTip>
              </StyledLink>
            </Loader>
          </FlexBox>
        )}
        {selectedContact?.contact?.fax && (
          <FlexBox>
            <StyledTitle>
              <img src={FaxIcon} style={IconStyle} />
              <span>FAX</span>
            </StyledTitle>
            <StyledTitle>
              <StyledValue>{selectedContact?.contact?.fax}</StyledValue>
            </StyledTitle>
          </FlexBox>
        )}
        {selectedContact.contact.email && (
          <FlexBox>
            <StyledTitle>
              <EmailOutlinedIcon style={IconStyle} />
              <span>Email</span>
            </StyledTitle>
            <StyledLink style={{ marginTop: "10px" }}>
              <ToolTip title="Click to Email" placement="top">
                <span
                  onClick={() => {
                    setEmailTo({
                      to: selectedContact.contact.email,
                      name:
                        contactOptions.find(
                          (c) => c.value === selectedContact.contact.contactId
                        )?.label || "",
                      relatesTo: relatesTo || "ACCOUNT",
                      opportunityId:
                        relatesTo === "OPPORTUNITY" ? relatesToId : undefined,
                      accountId: account.accountId
                    });
                  }}
                >
                  {selectedContact.contact.email}
                </span>
              </ToolTip>
              <ToolTip title="Copy Email to Clipboard" placement="top">
                <img
                  src={CopyIcon}
                  style={IconStyle}
                  onClick={() =>
                    copyToClipboard(selectedContact.contact.email, "Email")
                  }
                />
              </ToolTip>
            </StyledLink>
          </FlexBox>
        )}
      </div>
    );
  }
};
