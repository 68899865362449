import { FormHelperText, styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import React, { ChangeEvent, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DebitCardIcon from "@assets/icons/debitCardIcon.svg";
import CreditCardIcon from "@assets/icons/creditCardIcon.svg";
import { useForm } from "react-hook-form";
import { FormInput } from "@components/FormInput";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormSelect } from "@components/FormSelect";
import { Button } from "@components/Button";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { PasswordInput } from "@components/PasswordInput";
import { getCountries } from "@services/Network";
import { CRM_ORDER_BANK_ACH_TYPES } from "@utils/constants";
import { formatCurrency } from "@utils/formatCurrency";
import { AdvertiserInvoiceSchemaPaymentType } from "@sportsgravyengineering/sg-api-react-sdk";

const StyledDiv = styled("div")`
  box-shadow: 0px 0px 12px 0px #0000001f;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  width: 100%;
  max-width: 650px;
  padding: 24px;
  @media (max-width: 576px) {
    padding: 16px;
  }
`;

const StyledPaymentType = styled(Grid)`
  padding: 12px 24px;
  gap: 8px;
  border-radius: 4px;
  border: 1.5px solid #e5e5e5;
  height: 48px;
  cursor: pointer;
  @media (max-width: 576px) {
    padding: 10px 12px;
  }
`;

const StyledPaymentTypeName = styled("div")`
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
`;

const StyledButton = styled(Grid)`
  button {
    width: 100%;
  }
`;

const todayDate = () => {
  const today = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  return today.toLocaleDateString("en-US", options);
};

export const PaymentDetailsForm = ({
  onSaveClick,
  details,
  isSaving,
  setPaymentType
}: {
  onSaveClick: (data) => void;
  details: { amount: number };
  isSaving: boolean;
  setPaymentType?: (val: AdvertiserInvoiceSchemaPaymentType) => void;
}) => {
  const [selectedPaymentType, setSelectedPaymentType] =
    useState<AdvertiserInvoiceSchemaPaymentType>("DEBIT_CARD");
  const {
    control,
    reset,
    formState: { isValid },
    getValues
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      cvv: "",
      number: "",
      expirationDate: null,
      cardName: "",
      country: "",
      zipCode: "",
      billingInstructions: "",
      routingNumber: "",
      accountType: "",
      accountName: ""
    }
  });
  const { data: countries, isLoading: countryLoading } = getCountries({
    staleTime: Infinity
  });
  const onSave = async () => {
    onSaveClick(getValues());
  };
  return (
    <StyledDiv>
      <Typography style={{ fontWeight: 400, color: "#B3B3B3" }}>
        Payment Amount
      </Typography>
      <Typography style={{ fontSize: "24px", fontWeight: 700 }}>
        {formatCurrency(details?.amount)}
      </Typography>
      {selectedPaymentType !== "BANK_ACH" && (
        <Typography style={{ fontSize: "12px", fontWeight: 500 }}>
          + {formatCurrency(details.amount * 0.03)} (3% credit card charges)
        </Typography>
      )}
      <Grid container sx={{ marginTop: "24px" }} spacing={3}>
        <Grid>
          <StyledPaymentType
            xs={1}
            onClick={() => {
              setSelectedPaymentType("DEBIT_CARD");
              if (setPaymentType) setPaymentType("DEBIT_CARD");
              reset();
            }}
            sx={{
              ...(selectedPaymentType === "DEBIT_CARD"
                ? {
                    backgroundColor: "#F1F5FF",
                    border: "1.5px solid #2B337A"
                  }
                : {})
            }}
          >
            <img src={DebitCardIcon} />
          </StyledPaymentType>
          <StyledPaymentTypeName
            sx={{
              color: selectedPaymentType === "DEBIT_CARD" ? "#0F0F0F" : "#666",
              fontWeight: selectedPaymentType === "DEBIT_CARD" ? "600" : "500"
            }}
          >
            Debit Card
          </StyledPaymentTypeName>
        </Grid>
        <Grid>
          <StyledPaymentType
            xs={1}
            onClick={() => {
              setSelectedPaymentType("CREDIT_CARD");
              if (setPaymentType) setPaymentType("CREDIT_CARD");
              reset();
            }}
            sx={{
              ...(selectedPaymentType === "CREDIT_CARD"
                ? {
                    backgroundColor: "#F1F5FF",
                    border: "1.5px solid #2B337A"
                  }
                : {})
            }}
          >
            <img src={CreditCardIcon} />
          </StyledPaymentType>
          <StyledPaymentTypeName
            sx={{
              color: selectedPaymentType === "CREDIT_CARD" ? "#0F0F0F" : "#666",
              fontWeight: selectedPaymentType === "CREDIT_CARD" ? "600" : "500"
            }}
          >
            Credit Card
          </StyledPaymentTypeName>
        </Grid>

        <Grid>
          <StyledPaymentType
            xs={1}
            onClick={() => {
              setSelectedPaymentType("BANK_ACH");
              if (setPaymentType) setPaymentType("BANK_ACH");
              reset();
            }}
            sx={{
              ...(selectedPaymentType === "BANK_ACH"
                ? {
                    backgroundColor: "#F1F5FF",
                    border: "1.5px solid #2B337A"
                  }
                : {})
            }}
          >
            <AccountBalanceIcon />
          </StyledPaymentType>
          <StyledPaymentTypeName
            sx={{
              color: selectedPaymentType === "BANK_ACH" ? "#0F0F0F" : "#666",
              fontWeight: selectedPaymentType === "BANK_ACH" ? "600" : "500"
            }}
          >
            Bank (ACH)
          </StyledPaymentTypeName>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "24px" }} spacing={3}>
        {(selectedPaymentType === "CREDIT_CARD" ||
          selectedPaymentType === "DEBIT_CARD") && (
          <>
            <Grid md={6} xs={12}>
              <FormInput
                control={control}
                name="number"
                label="Card Number"
                type="text"
                required
                rules={{
                  required: "Card Number is required",
                  validate: (value) => {
                    value && value.length != 12 ? "Invalid Card Number" : true;
                  }
                }}
                TextProps={{
                  inputProps: {
                    maxLength: 16,
                    onInput: (e) => {
                      (e as ChangeEvent<HTMLInputElement>).target.value = (
                        e as ChangeEvent<HTMLInputElement>
                      ).target.value.replace(/[^0-9]/g, "");
                    }
                  }
                }}
              />
            </Grid>
            <Grid md={3} xs={6}>
              <FormDatePicker
                disablePast
                name={`expirationDate`}
                label="Expiration Date"
                views={["year", "month"]}
                format="MM/yyyy"
                control={control}
                required
                rules={{
                  required: "Expiration Date is required"
                }}
              />
            </Grid>
            <Grid md={3} xs={6}>
              <PasswordInput
                name={`cvv`}
                label="CVV"
                control={control}
                required
                rules={{
                  required: "CVV is required"
                }}
                TextProps={{
                  inputProps: {
                    maxLength: 3,
                    onInput: (e) => {
                      (e as ChangeEvent<HTMLInputElement>).target.value = (
                        e as ChangeEvent<HTMLInputElement>
                      ).target.value.replace(/[^0-9]/g, "");
                    }
                  }
                }}
              />
            </Grid>
            <Grid md={6} xs={12}>
              <FormInput
                control={control}
                name="cardName"
                label="Name on Card"
                type="text"
                rules={{
                  required: "Name on Card is required"
                }}
                required
              />
            </Grid>
            <Grid md={3} xs={6}>
              <FormSelect
                label="Country"
                name="country"
                control={control}
                required
                rules={{
                  required: "Country is required"
                }}
                isLoading={countryLoading}
                options={
                  countries
                    ? countries.map((c) => ({
                        label: c.name,
                        value: c.countryAltId
                      }))
                    : []
                }
              />
            </Grid>
            <Grid md={3} xs={6}>
              <FormInput
                control={control}
                name="zipCode"
                label="Zip Code"
                type="text"
                rules={{
                  required: "Zip Code is required"
                }}
                required
              />
            </Grid>
          </>
        )}
        {selectedPaymentType === "BANK_ACH" && (
          <>
            <Grid xs={12}>
              <FormSelect
                control={control}
                name="accountType"
                label="Account Type"
                options={CRM_ORDER_BANK_ACH_TYPES}
                required
                rules={{
                  required: "Account Type is required"
                }}
              />
            </Grid>
            <Grid xs={12}>
              <FormInput
                control={control}
                name="routingNumber"
                label="Routing Number"
                type="text"
                rules={{
                  required: "Routing Number is required"
                }}
                required
              />
            </Grid>
            <Grid md={6} xs={12}>
              <FormInput
                control={control}
                name="number"
                label="Account Number"
                type="text"
                rules={{
                  required: "Account Number is required"
                }}
                required
              />
            </Grid>
            <Grid md={6} xs={12}>
              <FormInput
                control={control}
                name="confirmAccNumber"
                label="Confirm Account Number"
                type="text"
                rules={{
                  required: "Confirm Account Number is required",
                  validate: (value) =>
                    value === getValues().number ||
                    "Confirm Account Number does not match"
                }}
              />
            </Grid>
            <Grid xs={12}>
              <FormInput
                control={control}
                name="accountName"
                label="Account Holder’s Name"
                type="text"
                required
                rules={{
                  required: "Account Holder's Name is required"
                }}
              />
            </Grid>
          </>
        )}
        <Grid xs={12}>
          <FormInput
            control={control}
            name="billingInstructions"
            label="Billing Instructions"
            type="text"
          />
        </Grid>
        <Grid xs={12}>
          <FormHelperText style={{ color: "#666" }}>
            {selectedPaymentType !== "BANK_ACH" ? (
              <span>
                {`By selecting Pay, you accept the Terms of Service. Our Global
              Privacy Statement will apply to any personal information you
              provide us. You also allow SportsGravy to charge
              ${formatCurrency(
                details.amount
              )} to your card on ${todayDate()}. Note: There is a 3% surcharge for paying this invoice by
              debit card`}
              </span>
            ) : (
              <>
                <span>{`By selecting Pay, you accept the Terms of Service. Our Global Privacy Statement will apply to any personal information you provide us. You also allow SportsGravy to charge ${formatCurrency(
                  details.amount
                )} to your bank account on ${todayDate()}.`}</span>
                <br />
                <span>
                  You also provide written instructions for SportsGravy to
                  obtain and periodically refresh information from bank
                  verification data providers to validate your bank account
                  information and for other fraud detection purposes.
                </span>
              </>
            )}
          </FormHelperText>
        </Grid>
        <StyledButton xs={12}>
          <Button
            variant="admin-primary"
            startIcon={<LockOutlinedIcon />}
            disabled={!isValid || isSaving}
            isLoading={isSaving}
            onClick={onSave}
          >
            Pay{" "}
            {formatCurrency(
              details.amount +
                (selectedPaymentType !== "BANK_ACH" ? details.amount * 0.03 : 0)
            )}
          </Button>
        </StyledButton>
        <Grid
          xs={12}
          sx={{ display: "flex", justifyContent: "center", padding: 0 }}
        >
          <Typography
            style={{
              fontSize: "12px",
              color: "#666",
              display: "flex",
              alignItems: "center",
              gap: "5px"
            }}
          >
            <LockOutlinedIcon style={{ height: "16px", width: "16px" }} />
            <span>Information is protected and kept confidential</span>
          </Typography>
        </Grid>
      </Grid>
    </StyledDiv>
  );
};
