import React from "react";
import { Container } from "@components/crud/Container";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/system/Unstable_Grid";
import { FormSelect } from "@components/FormSelect";
import { FormInput } from "@components/FormInput";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormTimePicker } from "@components/FormTimePicker";

const BOARDING_STATUS = [
  {
    label: "Not Ready",
    value: 0
  },
  {
    label: "Board Immediately",
    value: 1
  }
];

const MERCHANT_TYPE = [
  {
    label: "Merchant accepts cards in person primatily",
    value: "MERCHANT_ACCEPT_CARDS_IN_PERSON"
  },
  {
    label: "Customer enter their payment information online primarily",
    value: "CUSTOMER_ENTER_PAYMENT_INFORMATION"
  },
  {
    label: "Merchant is a fuel, petrol, or gas station",
    value: "MERCHANT_FUEL_STATION"
  },
  {
    label:
      "Merchant takes payment information over the phone or mail primarily",
    value: "MERCHANT_TAKES_PAYMENT_OVER_PHONE"
  },
  {
    label: "Merchant is a restaurant",
    value: "MERCHANT_RESTAURANT"
  },
  {
    label: "Merchant is a super market",
    value: "MERCHANT_SUPERMARKET"
  },
  {
    label: "Service station",
    value: "MERCHANT_SERVICE_STATION"
  }
];

export const AccountSetup = () => {
  const { control, watch, trigger } = useFormContext();
  const tncAccepted = watch("tnc");
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="status"
            label="Boarding Status"
            options={BOARDING_STATUS}
            required
            rules={{
              required: "Boarding Status is required"
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="mcc"
            label="Add MCC"
            options={[
              { label: "Sports and Riding Apparel Stores", value: "5655" },
              {
                value: "7941",
                label:
                  "Athletic Fields, Commercial Sports, Professional Sport Clubs, and Sport Promoters"
              },
              {
                value: "7997",
                label:
                  "Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses"
              }
            ]}
            required
            rules={{
              required: "MCC is required"
            }}
          />
        </Grid>
        <Grid xs={6}>
          <FormSelect
            control={control}
            name="merchantType"
            label="Merchant Type"
            options={MERCHANT_TYPE}
            required
            rules={{
              required: "Merchant Type is required"
            }}
          />
        </Grid>
        <Grid xs={6}></Grid>
        <Grid xs={12}>
          <FormCheckbox
            control={control}
            name="tnc"
            label="Accept Terms and Conditions"
            required
            rules={{
              required: "Terms and Conditions is required"
            }}
          />
        </Grid>
        {tncAccepted && (
          <>
            <Grid xs={6}>
              <FormSelect
                control={control}
                name="tcVersion"
                label="Version Accepted"
                options={[{ label: "World Pay", value: "10123WorldPay" }]}
                required
                rules={{
                  required: "Version Accepted is required"
                }}
              />
            </Grid>
            <Grid xs={3}>
              <FormDatePicker
                control={control}
                name="tcAcceptDate"
                label="Date"
                required
                rules={{
                  required: "Date is required"
                }}
                trigger={trigger}
                disableFuture
              />
            </Grid>
            <Grid xs={3}>
              <FormTimePicker
                control={control}
                name="tcAcceptTime"
                label="Time"
                required
                rules={{
                  required: "Time is required"
                }}
              />
            </Grid>
            <Grid xs={6}>
              <FormInput
                control={control}
                type="text"
                name="tcAcceptIp"
                label="IP Address"
                required
                rules={{
                  required: "IP Address is required"
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};
