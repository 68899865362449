/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { MediaSelector } from "@components/MediaSelector";
import { Grid, styled, FormLabel, Typography } from "@mui/material";
import { getImportTemplateProviders } from "@services/Network";
import {
  ModelMedia,
  useLookupCountryCountryIdSubdivisionGet,
  useMediaGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { isValidEmail } from "@utils/isValidEmail";
import { WEBSITE_REGEX } from "@utils/validation";
import { useMemo, useState, useEffect } from "react";
import UploadAvatarImage from "@assets/images/UploadAvatar.png";
import { Loader } from "@components/crud/Loader";
import { ORGANIZATION_TYPE_OPTIONS } from "@utils/constants";
import { capitalizeEveryWord } from "@utils/capitalize";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { FormCheckbox } from "@components/FormCheckbox";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { UseFormTrigger } from "react-hook-form";

const autocompleteService = { current: null } as any;
const autocompleteService1 = { current: null } as any;
const autocompleteService2 = { current: null } as any;
const libraries = ["places"] as "places"[];

const offeringOptions = [
  { label: "Teams", value: "TEAMS" },
  { label: "Training Programs", value: "TRAINING_PROGRAMS" },
  {
    label: "Teams and Training Programs",
    value: "TEAMS_AND_TRAINING_PROGRAMS"
  }
];

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const OrganizationDetailsForm = ({
  disabled,
  control,
  providerId,
  country,
  selectAddressSuggestion,
  isEditing,
  countries,
  isFetchingCountries,
  type,
  onMediaUpload,
  avatarId,
  setValue,
  filesValid,
  updatedFiles,
  isShippingSame,
  isBillingSame,
  setIsBillingSame,
  setIsShippingSame,
  trigger
}: {
  disabled: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  providerId: string;
  country: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectAddressSuggestion: any;
  isEditing: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countries: any;
  onMediaUpload?: (file: (File | ModelMedia)[]) => void;
  isFetchingCountries: boolean;
  type: string;
  avatarId?: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue?: any;
  filesValid?: (val: boolean) => void;
  updatedFiles?: (ModelMedia | File)[];
  isBillingSame?: boolean;
  isShippingSame?: boolean;
  setIsBillingSame?: (val: boolean) => void;
  setIsShippingSame?: (val: boolean) => void;
  trigger?: UseFormTrigger<any>;
}) => {
  const { data, isFetching } = getImportTemplateProviders(
    {},
    { staleTime: Infinity }
  );

  const { data: provinces } = useLookupCountryCountryIdSubdivisionGet(
    country as string,
    {
      query: {
        enabled: !!country
      }
    }
  );

  const provincesOptions = useMemo(() => {
    if (provinces) {
      return provinces.data.map((province) => ({
        value: province.subdivisionId as string,
        label: province.name as string
      }));
    }
    return [];
  }, [provinces]);

  const providerOptions = useMemo(() => {
    if (data) {
      return data.map((provider) => ({
        value: provider.providerId!,
        label: provider.name!
      }));
    }
    return [];
  }, [data]);

  const countryOptions = useMemo(() => {
    if (countries) {
      return countries.map((country) => ({
        value: country.countryId!,
        label: country.name!
      }));
    }
    return [];
  }, [countries]);

  const selectedCountry = useMemo(() => {
    if (countries?.length) {
      return countries?.find((c) => c.countryId === country);
    }
    return null;
  }, [country, countries]);

  const versionOptions = useMemo(() => {
    return (
      data
        ?.find((provider) => provider.providerId === providerId)
        ?.versions?.map((version) => ({
          value: version.versionId!,
          label: version.version!
        })) || []
    );
  }, [providerId, providerOptions]);

  const isTypeFieldDisabled = useMemo(() => {
    // When editing and type is Governing Body, disable the field
    // only if other organizations are governed by this organization
    return (
      disabled ||
      (isEditing &&
        [
          "NATIONAL_GOVERNING_BODY",
          "STATE_GOVERNING_BODY",
          "REGIONAL_GOVERNING_BODY",
          "LOCAL_GOVERNING_BODY"
        ].includes(type))
    );
  }, [isEditing, type]);
  const { data: mediaOutputs, isLoading: isLoading } = useMediaGet({
    mediaIds: [avatarId || ""]
  });

  const [newFileUploaded, setNewFileUploaded] = useState(false);
  const [filesPresent, setFilesPresent] = useState(true);
  const [avatarIdState, setAvatarIdState] = useState(avatarId);
  const [officeAddessSelected, setOfficeAddessSelected] = useState(true);
  const [billingAddessSelected, setBillingAddessSelected] = useState(true);
  const [shippingAddessSelected, setShippingAddessSelected] = useState(true);

  const [fileUploadClicked, setFileUploadClicked] = useState(false);
  useEffect(() => {
    setAvatarIdState(avatarId);
  }, [avatarId]);
  useEffect(() => {
    if (newFileUploaded) setFilesPresent(true);
    else if (avatarIdState) setFilesPresent(true);
    else if (updatedFiles && updatedFiles.length > 0) setFilesPresent(true);
    else setFilesPresent(false);
  }, [newFileUploaded, avatarIdState, updatedFiles]);

  useEffect(() => {
    if (filesValid) filesValid(filesPresent);
  }, [filesPresent]);

  const memoizedMediaSelector = useMemo(() => {
    return (
      <MediaSelector
        onlyImages
        disabled={disabled}
        removeFiles={() => {
          setAvatarIdState(undefined);
          setNewFileUploaded(false);
          setFileUploadClicked(true);
          onMediaUpload!([]);
        }}
        onAddFiles={(files) => {
          if (files.length && files[0] instanceof File) {
            onMediaUpload!(files);
            setNewFileUploaded(true);
            setFileUploadClicked(true);
          }
        }}
        maxImages={1}
        uploadedFiles={
          !newFileUploaded && avatarIdState
            ? mediaOutputs?.data?.media?.map((m) => m.media!) || []
            : []
        }
        filesStored={updatedFiles}
        imagePlaceHolder={UploadAvatarImage}
      />
    );
  }, [avatarIdState, mediaOutputs, newFileUploaded, updatedFiles]);

  useEffect(() => {
    if (trigger) {
      if (officeAddessSelected) {
        trigger("address1");
      }
      if (billingAddessSelected) {
        trigger("billingAddress.address1");
      }
      if (shippingAddessSelected) {
        trigger("shippingAddress.address1");
      }
    }
  }, [officeAddessSelected, billingAddessSelected, shippingAddessSelected]);

  return (
    <Grid
      data-testid="org-detail-form"
      container
      direction="column"
      spacing="25px"
    >
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={12} container direction="column">
          <StyledFormLabel required>
            <Typography variant="formLabel">Avatar</Typography>
          </StyledFormLabel>
        </Grid>
        <Grid item container direction="column">
          <Grid item marginLeft="-12px" marginTop="-25px">
            <Loader isLoading={isLoading}>{memoizedMediaSelector}</Loader>
          </Grid>
          {!disabled && !filesPresent && fileUploadClicked && (
            <Grid item style={{ marginTop: "-10px" }}>
              <Typography style={{ color: "#E82C2C" }} variant="body2">
                Avatar is required
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid data-testid="org-name" item xs={9}>
          <FormInput
            name="name"
            control={control}
            rules={{ required: "Name is required" }}
            label="Name"
            type="text"
            required={true}
            disabled={disabled}
            onChange={(e) => {
              setValue("name", capitalizeEveryWord(e.target.value));
            }}
          />
        </Grid>
        <Grid data-testid="org-abbr" item xs={3}>
          <FormInput
            capitalize
            name="abbreviation"
            control={control}
            rules={{
              required: "Abbreviation is required",
              maxLength: {
                value: 8,
                message: "Abbreviation must be 8 characters or less"
              }
            }}
            label="Abbreviation"
            type="text"
            required
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid data-testid="org-type" item xs={6}>
          <FormSelect
            name="type"
            control={control}
            rules={{ required: "Type is required" }}
            options={ORGANIZATION_TYPE_OPTIONS}
            label="Type"
            required
            disabled={isTypeFieldDisabled}
          />
        </Grid>
        <Grid data-testid="org-offerings" item xs={6}>
          <FormSelect
            name="offering"
            control={control}
            rules={{ required: "Offering is required" }}
            options={offeringOptions}
            label="Offering"
            required
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <Grid item container direction="row" spacing="24px">
        <Grid data-testid="org-phone" item xs={6}>
          <FormInput
            name="phone"
            type="tel"
            country={selectedCountry}
            required
            label="Mobile Phone"
            control={control}
            rules={{
              required: "Mobile Phone is required"
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid data-testid="org-email" item xs={6}>
          <FormInput
            name="email"
            type="text"
            required
            label="Email"
            control={control}
            disabled={disabled}
            rules={{
              required: "Email is required",
              validate: {
                validEmail: (email: string) =>
                  isValidEmail(email) || "Please enter a valid email address"
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid data-testid="org-website" item xs={6}>
          <FormInput
            name="website"
            control={control}
            label="Website"
            type="text"
            disabled={disabled}
            rules={{
              validate: {
                validUrl: (url: string) =>
                  url === "" ||
                  WEBSITE_REGEX.test(url) ||
                  "Please enter a valid website url"
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid data-testid="org-providerId" item xs={6}>
          <FormSelect
            name="providerId"
            control={control}
            label="Upload Athletes/Players Template Provider"
            options={providerOptions}
            required
            rules={{
              required: "Upload Athletes/Players Template Provider is required"
            }}
            onChange={(e) => {
              setValue("providerId", e.target.value);
              setValue("versionId", "");
            }}
            disabled={disabled}
            isLoading={isFetching}
          />
        </Grid>
        <Grid data-testid="org-versionId" item xs={6}>
          <FormSelect
            name="versionId"
            control={control}
            rules={{
              required:
                "Upload Athletes/Players Template Provider Version is required"
            }}
            label="Upload Athletes/Players Template Provider Version"
            options={versionOptions}
            required
            disabled={disabled}
            isLoading={isFetching}
          />
        </Grid>
        <LoadScript
          googleMapsApiKey={import.meta.env.VITE_APP_MAP_API_KEY as string}
          libraries={libraries}
          loadingElement={<LoadingSpinner />}
        >
          <Grid item xs={12}>
            <Typography
              variant="permissionNames"
              sx={{
                fontWeight: 400,

                letterSpacing: "10%",
                lineHeight: "14.52px",
                opacity: "50%"
              }}
            >
              OFFICE ADDRESS
            </Typography>
            <HeaderUnderLine />
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid data-testid="org-country" item xs={6}>
              <FormSelect
                name="country"
                control={control}
                rules={{ required: "Country is required" }}
                options={countryOptions}
                isLoading={isFetchingCountries}
                label="Country"
                required
                disabled={disabled || isEditing}
              />
            </Grid>
            <Grid data-testid="org-address1" item xs={6}>
              <Autocomplete
                className="address-autocomplete"
                onLoad={(autocomplete) => {
                  autocompleteService.current = autocomplete;
                }}
                onPlaceChanged={() => {
                  if (autocompleteService.current) {
                    const place = autocompleteService.current.getPlace();
                    selectAddressSuggestion(place);
                    setOfficeAddessSelected(true);
                  }
                }}
              >
                <FormInput
                  name="address1"
                  control={control}
                  rules={{
                    required: "Address 1 is required",
                    validate: () => {
                      return !officeAddessSelected
                        ? "The address entered is invalid, please make a recommended selection"
                        : undefined;
                    }
                  }}
                  label="Address 1"
                  required
                  type="text"
                  disabled={disabled}
                  onChange={() => {
                    setOfficeAddessSelected(false);
                  }}
                />
              </Autocomplete>
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid data-testid="org-address2" item xs={6}>
              <FormInput
                name="addressLine2"
                control={control}
                label="Address Line 2"
                type="text"
                disabled={disabled}
              />
            </Grid>
            <Grid data-testid="org-locality" item xs={6}>
              <FormInput
                name="locality"
                control={control}
                rules={{ required: "City is required" }}
                label="City"
                type="text"
                required
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="24px">
            <Grid data-testid="org-province" item xs={6}>
              <FormSelect
                name="province"
                control={control}
                rules={{ required: "State/Province is required" }}
                label="State/Province"
                options={provincesOptions}
                type="text"
                required
                disabled={disabled}
              />
            </Grid>
            <Grid data-testid="org-zipcode" item xs={6}>
              <FormInput
                name="postalCode"
                control={control}
                rules={{ required: "Zip is required" }}
                label="Zip"
                type="text"
                required
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing="10px">
            <Grid item>
              <FormCheckbox
                control={control}
                name="isBillingSameAs"
                label="Billing Address is the same as Office Address"
                disabled={disabled}
                onChange={(e) => {
                  if (setIsBillingSame) {
                    setIsBillingSame(e.target.checked);
                  }
                }}
              />
            </Grid>
          </Grid>
          {!isBillingSame && (
            <Grid
              item
              container
              spacing="20px"
              // style={{ visibility: isBillingSame ? "hidden" : "visible" }}
              // height={isBillingSame ? "0px" : "auto"}
            >
              <Grid item xs={12}>
                <Typography
                  variant="permissionNames"
                  sx={{
                    fontWeight: 400,

                    letterSpacing: "10%",
                    lineHeight: "14.52px",
                    opacity: "50%"
                  }}
                >
                  BILLING ADDRESS
                </Typography>
                <HeaderUnderLine />
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <FormSelect
                    name="billingAddress.country"
                    control={control}
                    rules={{ required: "Country is required" }}
                    options={countryOptions}
                    isLoading={isFetchingCountries}
                    label="Country"
                    required
                    disabled={disabled || isEditing}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className="address-autocomplete"
                    onLoad={(autocomplete) => {
                      autocompleteService1.current = autocomplete;
                    }}
                    onPlaceChanged={() => {
                      if (autocompleteService1.current) {
                        const place = autocompleteService1.current.getPlace();
                        selectAddressSuggestion(place, "billingAddress.");
                        setBillingAddessSelected(true);
                      }
                    }}
                  >
                    <FormInput
                      name="billingAddress.address1"
                      control={control}
                      rules={{
                        required: "Address 1 is required",
                        validate: () => {
                          return billingAddessSelected
                            ? true
                            : "The address entered is invalid, please make a recommended selection";
                        }
                      }}
                      label="Address 1"
                      required
                      type="text"
                      disabled={disabled}
                      onChange={() => {
                        setBillingAddessSelected(false);
                      }}
                    />
                  </Autocomplete>
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <FormInput
                    name="billingAddress.addressLine2"
                    control={control}
                    label="Address Line 2"
                    type="text"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="billingAddress.locality"
                    control={control}
                    rules={{ required: "City is required" }}
                    label="City"
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <FormSelect
                    name="billingAddress.province"
                    control={control}
                    rules={{ required: "State/Province is required" }}
                    label="State/Province"
                    options={provincesOptions}
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="billingAddress.postalCode"
                    control={control}
                    rules={{ required: "Zip is required" }}
                    label="Zip"
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item container direction="row" spacing="10px">
            <Grid item>
              <FormCheckbox
                control={control}
                name="isShippingSameAs"
                label="Shipping Address is the same as "
                disabled={disabled}
                onChange={(e) => {
                  if (setIsShippingSame) {
                    setIsShippingSame(e.target.checked);
                  }
                }}
              />
            </Grid>
            <Grid item marginTop="10px">
              <FormSelect
                sx={{
                  height: "20px"
                }}
                control={control}
                disabled={disabled}
                name="shippingSameAs"
                options={[
                  {
                    label: "Office Address",
                    value: "OFFICE"
                  },
                  {
                    label: "Billing Address",
                    value: "BILLING"
                  }
                ]}
              />
            </Grid>
          </Grid>
          {!isShippingSame && (
            <Grid
              item
              container
              spacing="20px"
              // style={{ visibility: isShippingSame ? "hidden" : "visible" }}
              // height={isShippingSame ? "0px" : "auto"}
            >
              <Grid item xs={12}>
                <Typography
                  variant="permissionNames"
                  sx={{
                    fontWeight: 400,

                    letterSpacing: "10%",
                    lineHeight: "14.52px",
                    opacity: "50%"
                  }}
                >
                  SHIPPING ADDRESS
                </Typography>
                <HeaderUnderLine />
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <FormSelect
                    name="shippingAddress.country"
                    control={control}
                    rules={{ required: "Country is required" }}
                    options={countryOptions}
                    isLoading={isFetchingCountries}
                    label="Country"
                    required
                    disabled={disabled || isEditing}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className="address-autocomplete"
                    onLoad={(autocomplete) => {
                      autocompleteService2.current = autocomplete;
                    }}
                    onPlaceChanged={() => {
                      if (autocompleteService2.current) {
                        const place = autocompleteService2.current.getPlace();
                        selectAddressSuggestion(place, "shippingAddress.");
                        setShippingAddessSelected(true);
                      }
                    }}
                  >
                    <FormInput
                      name="shippingAddress.address1"
                      control={control}
                      rules={{
                        required: "Address 1 is required",
                        validate: () => {
                          return shippingAddessSelected
                            ? true
                            : "The address entered is invalid, please make a recommended selection";
                        }
                      }}
                      label="Address 1"
                      required
                      type="text"
                      disabled={disabled}
                      onChange={() => {
                        setShippingAddessSelected(false);
                      }}
                    />
                  </Autocomplete>
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <FormInput
                    name="shippingAddress.addressLine2"
                    control={control}
                    label="Address Line 2"
                    type="text"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="shippingAddress.locality"
                    control={control}
                    rules={{ required: "City is required" }}
                    label="City"
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6}>
                  <FormSelect
                    name="shippingAddress.province"
                    control={control}
                    rules={{ required: "State/Province is required" }}
                    label="State/Province"
                    options={provincesOptions}
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    name="shippingAddress.postalCode"
                    control={control}
                    rules={{ required: "Zip is required" }}
                    label="Zip"
                    type="text"
                    required
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </LoadScript>
      </Grid>
    </Grid>
  );
};

OrganizationDetailsForm.defaultProps = {
  countries: [],
  isFetchingCountries: false
};
